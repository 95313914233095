/* eslint-disable class-methods-use-this */
import { TransportEvent } from 'geeq_node_core/src/transport/interfaces';
import { Transport } from 'geeq_node_core/src/transport';
import { Crypto } from 'geeq_node_core/src/libraries/crypto';
import { Node } from 'geeq_node_core/src/actors/interfaces';
import { Logger } from 'geeq_node_core/src/logger/interfaces/logger';
import { PublicKeyBuffer } from 'geeq_node_core/src/capnp/buffers/public-key';
import {
  NetworkActorRecordWrapper,
} from 'geeq_node_core/src/capnp/wrappers/network-actor-record';
import {
  ApplicationPayloadWrapper,
} from 'geeq_node_core/src/capnp/wrappers/node-payload-list/app-payload';
import { BaseBuffer } from 'geeq_node_core/src/capnp/buffers/base';
import {
  UnverifiedApplicationTransactionWrapper,
} from 'geeq_node_core/src/capnp/wrappers/unverified-application-transaction';

import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import {
  NodePublicKeyUndefinedError,
} from 'geeq_node_core/src/libraries/errors/node-public-key-undefined';

import { GroupType } from '@geeqdev/geeq_capnp_ts/dest/messageTypes.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/dest/messageTxTypes.capnp';
import { UatDataFeeSource } from '@geeqdev/geeq_capnp_ts/dest/uat.capnp';

import { Dispatcher } from '@/models/worker/dispatcher';
import {
  UnverifiedValidationTransactionWrapper,
} from 'geeq_node_core/src/capnp/wrappers/unverified-validation-transaction';
import {
  AccountNumMultiUserWrapper,
} from 'geeq_node_core/src/capnp/wrappers/unverified-validation-transaction/account-num-multi-user';
import { AccountHashBuffer } from 'geeq_node_core/src/capnp/buffers/account-hash';
import {
  ProxyCreateNotaryTransactionWrapper,
} from 'geeq_node_core/src/capnp/wrappers/unverified-validation-transaction/proxy-create-notary-transaction';
import {
  ProxyCreateTransactionPayloadWrapper,
} from 'geeq_node_core/src/capnp/wrappers/unverified-validation-transaction/proxy-create-transaction-payload';
import { Subtle } from '@/models/subtle';
import { UserAccountTypes } from 'geeq_node_core/src/capnp/wrappers/unverified-application-transaction/interfaces';
import { IdBuffer } from 'geeq_node_core/src/capnp/buffers/id';
import { CoinAccountRecordWrapper } from 'geeq_node_core/src/capnp/wrappers/coin-account-record';

export class Transaction {
  private readonly transport: Transport;

  private readonly crypto: Crypto;

  private readonly node: Node;

  private readonly dispatcher: Dispatcher;

  private readonly logger: Logger;

  private publicKey?: PublicKeyBuffer;

  private id?: IdBuffer;

  constructor(
    crypto: Crypto,
    transport: Transport,
    dispatcher: Dispatcher,
    node: Node,
    logger: Logger,
  ) {
    this.crypto = crypto;
    this.transport = transport;
    this.node = node;
    this.dispatcher = dispatcher;
    this.logger = logger;
  }

  public getNode(): Node {
    return this.node;
  }

  public async initKeys(): Promise<void> {
    await this.crypto.generatePair();

    this.publicKey = this.crypto.getPublicKey();

    this.id = await this.crypto.hashBuffer(this.publicKey, 32);
  }

  public getPublicKey(): PublicKeyBuffer {
    if (!this.publicKey) {
      throw new Error('Public key not initialized');
    }

    return this.publicKey;
  }

  public getId(): IdBuffer {
    if (!this.id) {
      throw new Error('Id not initialized');
    }

    return this.id;
  }

  public getAppCommitHash(): string {
    return new Array(32)
      .fill(1)
      .map(() => Math.floor(Math.random() * 256).toString(16))
      .map((elm) => (elm.length === 2 ? elm : `0${elm}`))
      .join('');
  }

  public async nodeIpList(): Promise<Array<string>> {
    const vll = await this.node.getCurrentVll();

    return vll.actives
      .map((node: NetworkActorRecordWrapper) => node.ipAddress);
  }

  public async listenerIpList(): Promise<Array<string>> {
    const vll = await this.node.getCurrentVll();

    return vll.listeners
      .map((node: NetworkActorRecordWrapper) => node.ipAddress);
  }

  public async getNodePublicKeyByIp(
    ip: string,
  ): Promise<PublicKeyBuffer | undefined> {
    const vll = await this.node.getCurrentVll();
    const node = vll
      .allNodes
      .find(
        (node: NetworkActorRecordWrapper) => node.ipAddress === ip,
      );

    if (!node) {
      return undefined;
    }

    return node.getPublicKey();
  }

  public async getNodeNumberByIp(
    ip: string,
  ): Promise<number | undefined> {
    const vll = await this.node.getCurrentVll();
    const node = vll
      .allNodes
      .find(
        (node: NetworkActorRecordWrapper) => node.ipAddress === ip,
      );

    if (!node) {
      return undefined;
    }

    return node.number;
  }

  public async notary(
    ip: string,
    hash: string,
    meta: Array<string>,
    nonce: number,
    accountType: UserAccountTypes,
    accountId: IdBuffer,
    publicKey: PublicKeyBuffer,
  ): Promise<boolean> {
    const vll = await this.node.getCurrentVll();

    const payload = ApplicationPayloadWrapper.createNotaryPayload(
      BaseBuffer.fromHexString(hash),
      meta.map((item) => {
        // todo need change random length
        const buffer = BaseBuffer.fromText(item).toUint8Array();
        const newBuffer = new Uint8Array(64);
        buffer.forEach((elm, index) => {
          newBuffer[index] = elm;
        });

        return new BaseBuffer(newBuffer);
      }),
    );
    payload.setContext({
      dispatcher: this.dispatcher,
      crypto: this.crypto,
    });
    await payload.computeRefHash();
    const refHash = payload.getRefHash();

    const uat = UnverifiedApplicationTransactionWrapper.create(
      vll.getChainNumber(),
      vll.getBlockNumber() + 1,
      GroupType.GT_UNVER_APP_USER_TX,
    );
    uat.setTxType(TxType.APP_NOTARY_COMMIT_TX);
    uat.setRefHash(refHash);

    uat.setNonce(nonce);

    uat.setAmtTx(GeeqAmount.fromGeeqs(0));
    uat.setFeeSource(UatDataFeeSource.FEE_COIN);

    // todo need set actual value
    uat.setAcctNumVal(accountId);
    uat.setUserAccountType(accountType);

    // uat.setAcctNumApp(
    //   new PublicKeyBuffer(new Uint8Array(32).fill(1)),
    // );

    const nodePublicKey = await this.getNodePublicKeyByIp(ip);

    if (!nodePublicKey) {
      throw new NodePublicKeyUndefinedError();
    }

    uat.setNodePublicKey(nodePublicKey);
    uat.setPubKeyUser(publicKey);

    uat.setContext({
      dispatcher: this.dispatcher,
      crypto: this.crypto,
    });
    await uat.sign();

    await this.transport.send(
      ip,
      TransportEvent.uat,
      {
        transaction: uat.getBuffer(),
        payload: payload.getBuffer(),
      },
    );
    return true;
  }

  public async createNotaryProxy(
    ip: string,
  ): Promise<[Crypto, Crypto]> {
    const vll = await this.node.getCurrentVll();

    const subtle = new Subtle();
    const userCrypto = new Crypto(subtle);
    await userCrypto.generatePair();
    const userAccHash = await userCrypto.hashBuffer(userCrypto.getPublicKey(), 32) as AccountHashBuffer;
    const user = AccountNumMultiUserWrapper.create(userAccHash);

    const adminCrypto = new Crypto(subtle);
    await adminCrypto.generatePair();
    const adminAccHash = await adminCrypto.hashBuffer(adminCrypto.getPublicKey(), 32) as AccountHashBuffer;

    const notaryTx = ProxyCreateNotaryTransactionWrapper.create(adminAccHash, [user]);

    const vllRecord = vll.getValidationLayerRecordById(
      this.getId().toString(),
    );
    const nonce = (vllRecord.getRecord() as CoinAccountRecordWrapper).getNonce();

    const proxyCreate = ProxyCreateTransactionPayloadWrapper.createNotary(
      GeeqAmount.fromGeeqs(3),
      nonce,
      this.getPublicKey(),
      adminAccHash as IdBuffer,
      notaryTx,
    );

    const nodeNumber = await this.getNodeNumberByIp(ip);

    const uvt = UnverifiedValidationTransactionWrapper.createProxyCreate(
      vll.getChainNumber(),
      vll.getBlockNumber() + 1,
      nodeNumber || 0,
      TxType.PROXY_CREATE_NOTARY_TX,
      proxyCreate,
    );

    uvt.setContext({
      dispatcher: this.dispatcher,
      crypto: this.crypto,
    });

    uvt.updateState();
    await uvt.sign();

    await this.transport.send(
      ip,
      TransportEvent.uvt,
      {
        transaction: uvt.getBuffer(),
      },
    );

    return [userCrypto, adminCrypto];
  }
}
