/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
import {
  Dispatcher as DispatcherInterfaces,
  Platform,
} from 'geeq_node_core/src/worker/interfaces';

import { WebError } from '@/error';
import { Workers } from '@/models/worker/interfaces';

import { WorkerThread } from './worker';

export class Dispatcher implements DispatcherInterfaces {
  public get platform(): Platform {
    return Platform.web;
  }

  public async create(dir: string): Promise<WorkerThread> {
    return new WorkerThread(
      Dispatcher.callerToWorker(
        Dispatcher.getCaller(),
      ),
    );
  }

  private static getCaller(): string {
    const { stack } = new WebError();

    if (!stack) {
      throw new Error('stack not found.');
    }

    const rows = stack.split('\n');
    const methodReg = '(?<method>\\w+.\\w+)';
    const pathReg = '(?<path>[\\w|\\/|_|\\.|:|\\d|-]+)';
    const rowReg = '(?<row>\\d+)';
    const colReg = '(?<column>\\d+)';
    const reg = new RegExp(
      `^\\s+at\\s+${methodReg}\\s+\\(${pathReg}:${rowReg}:${colReg}\\)$`,
    );

    const match = rows[3].match(reg);

    if (!match || !match.groups || !match.groups.path) {
      throw new Error('worker path regexp not match');
    }

    return match.groups.method;
  }

  private static callerToWorker(caller: string): Workers {
    switch (caller) {
      case 'getUatFlatten':
        return Workers.getUatFlatten;
      case 'getUvtFlatten':
        return Workers.getUvtFlatten;
      case 'getNtlRefMerkleRootHash':
        return Workers.getNtlRefMerkleRootHash;
      case 'getNplRefMerkleRootHash':
        return Workers.getNplRefMerkleRootHash;
      default:
        return Workers.none;
    }
  }
}
