<template>
  <div>
    <div
      v-if="$route.name!=='Message-details'"
      class="bg-white flex header-bar justify-center mx-auto"
      style="height: 150px;"
    >
      <div
        class="bg-white flex header-margin items-end w-10/12"
        :class="{'border-b border-gray': $route.name==='Discover'}"
        style="height: 100%"
      >
        <div class="header-bar-left">
          <div
            class="inline-block logo-wrap my-5 w-auto"
            itemscope="itemscope"
            itemtype="https://schema.org/Organization"
            style="min-width: 195px"
          >
            <a
              v-if="$route.name === 'Home'"
              href="https://geeq.io"
              title="go to the Geeq main website"
              class="logo-link"
            >
              <img src="/img/Geeq_green.svg" alt="test"/>
            </a>
            <template v-else>
              <router-link to="/" v-if="isGeeqLogoVisible">
                <img src="/img/geeqpayIcon.png" alt="test"/>
              </router-link>
              <div v-else>
<!--                <h1 class="text-5xl text-green uppercase">-->
<!--                  &lt;!&ndash; Where HOME used to be &ndash;&gt;-->
<!--                </h1>-->
              </div>
            </template>
          </div>
          <!-- end header-logo-wrap -->
        </div>
        <!-- end header-bar-left -->

        <div class="header-bar-right">
          <div id="nav">
            <div id="home-nav-link" class="nav-link-wrapper">
              <router-link to="/">Home</router-link>
              <div class="box arrow-top" v-if="showAlert">
                <div class="triangle-top">
                  <div class="inner-triangle"></div>
                </div>
                To begin, please go to the homepage and press `start`.
              </div>
            </div>
            <router-link :to="{ name: 'Discover' }" id="about-nav-link">
              Discover
            </router-link>
            <!-- <router-link to="/settings" id="settings-nav-link">Settings</router-link> -->
            <router-link to="/about" id="about-nav-link">About</router-link>
            <router-link to="/faq" id="faq-nav-link">FAQs</router-link>
          </div>
        </div>
        <!-- end header-bar-right -->

        <div id="app"></div>
      </div>
      <!-- end inner-margin -->
    </div>
    <!-- end header-bar -->
    <OopsMessageModal v-if="showOopsModal" @closeOopsModal="closeOopsModal" />
    <NewVisitorModal
      v-if="showNewVisitorModal"
      @closeVisitorModal="closeVisitorModal"
    />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OopsMessageModal from '@/components/OopsMessageModal';
import NewVisitorModal from '@/components/NewVisitorModal';
import mixinDetectingClient from './mixins/mixinDetectingClient';

export default {
  name: 'App',
  components: {
    OopsMessageModal,
    NewVisitorModal,
  },
  mixins: [mixinDetectingClient],
  data() {
    return {
      visitorModalClosed: false,
      OopsModalClosed: false,
      window: {
        width: 0,
      },
    };
    //
  },
  // computed: mapState({
  //   isChromeBrowser: (state) => state.isChromeBrowser,
  //   isMobileDevice: (state) => state.isMobileDevice,
  // }),
  computed: {
    ...mapGetters({
      isChromeBrowser: 'getIsChromeBrowser',
      isMobileDevice: 'getIsMobileDevice',
      simulationState: 'getSimulationState',
      theGeeqCorp: 'gettheGeeqCorp',
    }),
    showAlert() {
      return this.$route.name === 'Products' && this.simulationState !== 2;
    },
    showNewVisitorModal() {
      return !this.checkIfNeedToShow('doNotShow') && !this.visitorModalClosed;
    },
    showOopsModal() {
      return (
        this.window.width < 1050
        || (!this.OopsModalClosed
        && !this.showNewVisitorModal
        && (!this.isChromeBrowser || this.isMobileDevice))
      );
    },
    isGeeqLogoVisible() {
      let visible = false;
      switch (this.$route.name) {
        case 'About':
          visible = true;
          break;
        case 'FAQ':
          visible = true;
          break;
        case 'Settings':
          visible = true;
          break;
        default:
          visible = false;
      }
      return visible;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.$store.commit('setIsChromeBrowser', this.isChrome());
    this.$store.commit('setIsMobileDevice', this.isMobile());

    window.addEventListener('beforeunload', () => {
      window.postMessage({ type: 'FROM_PAGE_REFRESH', refresh: true }, '*');
    });
    if (this.isChromeBrowser) {
      // eslint-disable-next-line no-undef
      if (!chrome.runtime) {
        this.$store.commit('setIsExtensionExists', false);
      } else {
        try {
          // eslint-disable-next-line no-undef
          chrome.runtime.sendMessage(
            'gknpodfohhcpbjdjdpkaemgaddahmijf',
            { message: 'version' },
            (reply) => {
              if (reply) {
                if (reply.version) {
                  if (parseInt(reply.version, 10) >= 1) {
                    this.$store.commit('setIsExtensionExists', true);
                  }
                }
              } else {
                this.$store.commit('setIsExtensionExists', false);
              }
            },
          );
        } catch (err) {
          this.$store.commit('setIsExtensionExists', false);
        }
      }
    }
  },
  mounted() {
    window.addEventListener('message', async (event) => {
      if (event.data.type === 'FROM_CONTENT_AVAILABLE') {
        this.$store.commit('setAvailableTokensCount', event.data.count);
      }
      if (event.data.type === 'NEW_USER_DATA') {
        this.$store.commit('setWalletUserData', event.data);
      }
    });
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    eventLogger(event) {
      this.$store.state.eventLog.unshift(event);
    },
    closeOopsModal() {
      this.OopsModalClosed = true;
    },
    closeVisitorModal() {
      this.visitorModalClosed = true;
    },
    checkIfNeedToShow(cname) {
      const name = `${cname}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
          c.substring(name.length, c.length);

          return c.substring(name.length, c.length);
        }
      }

      return '';
    },
  },
};
</script>
<style src="./assets/css/index.css" lang="css"></style>

<style lang="scss">
* {
  font-family: 'Jost', sans-serif;
}
#nav {
  a {
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
div.header-bar-left {
  width: 50%;
  float: left;
  text-align: left;
}
div.header-bar-left img {
  max-width: initial;
  height: 60px;
  width: auto;
  max-width: 190px;
  height: auto;
  position: relative;
  bottom: -10px;
  transform: translate(0px, 10px);
}
div.header-bar-right {
  width: 50%;
  float: right;
  padding-top: 30px;
}
div#nav {
  width: auto;
  float: right;
}
div#nav a {
  margin-left: 1.5vw;
  font-size: 20px;
  color: #231f20;
}
#nav a.router-link-exact-active {
  color: #479997;
}
.box {
  width: 320px;
  height: auto;
  background-color: #fff;
  color: #414d78;
  padding: 20px;
  margin: 40px;
  position: absolute;
  right: -137px;
  top: 30px;
  border: 2px solid #d66339;
  font-family: 'Poppins-Light', sans-serif;
  text-transform: uppercase;
}
.nav-link-wrapper {
  display: inline-block;
  width: auto;
  position: relative;
}
.triangle-top {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid red;
  position: absolute;
  top: -40px;
  right: 107px;
}

.inner-triangle {
  position: relative;
  top: 6px;
  left: 2px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid #fff;
}
</style>
