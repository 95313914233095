/* eslint-disable no-shadow,class-methods-use-this */
// #region imports
// Libraries
import { Message } from 'capnp-ts';
// Capnp messages
import { HlCreatePayload, HlTxPayload, UvtData } from '@geeqdev/geeq_capnp_ts/generated/uvt.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/generated/messageTxTypes.capnp';

// Message Wrappers

// Actor related
import { GeeqUser } from 'geeq_node_core/src/actors/geeq-user';

// Utilities
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import { HashDigest } from 'geeq_node_core/src/libraries/buffers/hash-digest';

// Peer modules
import { Preimage } from 'geeq_node_core/src/libraries/preimage';

import { Subtle } from '@/models/subtle';
import { Serializer } from '@/models/serializer';

import UvtTester from './UvtTester';

// #endregion
enum PreImageType {
  P1 = 0, // typically used for HL_CREATE_STAND_TX
  P2, // typically used for HL_CREATE_CERT_TX
  P3a, // typically used for HL_CREATE_SEC_TX (hlDigest1)
  P3b, // typically used for HL_CREATE_SEC_TX (hlDigest2)
  P1x, // Used for HL_CREATE_SEC_TX (transfer) (hlDigest1)
  P2x, // Used for HL_CREATE_SEC_TX (transfer) (hlDigest2)
  P4x, // typically used for HL_TRANSFER_SEC_TX (hlDigest1)
  P5x, // typically used for HL_TRANSFER_sEC_TX (hlDigest2)
}
class HlTester extends UvtTester {
  async sendUvtHlCreateTxPayloadMessage(
    txType: TxType,
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
    nonce: number,
    amtTx: GeeqAmount,
    blockNumRfd: number,
    preImage: ArrayBuffer,
    callbackFn: (hlCreatePayload: HlCreatePayload) => Promise<void>,
  ) {
    const uvtCallbackFn = async (uvtData: UvtData) => {
      // Payload data
      const hlCreatePayloadMessage = new Message();
      const hlCreatePayload = hlCreatePayloadMessage.initRoot(HlCreatePayload);

      // Set common fields
      hlCreatePayload.setNonce(nonce);
      hlCreatePayload.setAmtTx(amtTx.toUint64());
      hlCreatePayload.setBlockNumRfd(blockNumRfd);

      // Set hlDigest1
      const hashDigest = new HashDigest(
        new Preimage(new Subtle()),
        new Serializer(),
        null,
      );
      const hlDigest1 = await hashDigest.fromPreImage(preImage);
      if (hlDigest1.buffer == null) {
        throw new Error('hlDigest1 buffer is null in testHLCreateCert');
      }
      hlCreatePayload.initHlDigest1(hlDigest1.buffer.byteLength);
      hlCreatePayload.getHlDigest1().copyBuffer(hlDigest1.buffer);

      // Set create cert specific fields
      await callbackFn(hlCreatePayload);

      // Save my public key
      const pubKeyUser = sendingUser.getPublicAccountKey();
      if (pubKeyUser !== null) {
        hlCreatePayload
          .initPubKeyUser(pubKeyUser.getBuffer().byteLength)
          .copyBuffer(pubKeyUser.getBuffer());
      } else {
        throw new Error('Uninitialized public key');
      }

      uvtData.initHlCreatePayload();
      uvtData.setHlCreatePayload(hlCreatePayload);
    };

    this.sendUvtMessage(
      txType,
      sendingUser,
      blockNumber,
      chainNumber,
      uvtCallbackFn,
    );
  }

  async sendUvtHlTxPayloadMessage(
    txType: TxType,
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
    callbackFn: (hlTxPayload: HlTxPayload) => Promise<void>,
  ) {
    const uvtCallbackFn = async (uvtData: UvtData) => {
      // Payload data
      const hlTxPayloadMessage = new Message();
      const hlTxPayload = hlTxPayloadMessage.initRoot(HlTxPayload);

      // Set cert specific fields
      await callbackFn(hlTxPayload);

      uvtData.initHlTxPayload();
      uvtData.setHlTxPayload(hlTxPayload);
    };
    this.sendUvtMessage(
      txType,
      sendingUser,
      blockNumber,
      chainNumber,
      uvtCallbackFn,
    );
  }

  /*
   * Menu of preImages.  Each preImage has a different value.
   */
  getPreImage(index: PreImageType): Uint8Array {
    const basePreImage = new Uint8Array([
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
    ]);
    switch (index) {
      case PreImageType.P1:
        basePreImage[0] = 1;
        break;
      case PreImageType.P2:
        basePreImage[0] = 2;
        break;
      case PreImageType.P3a:
        basePreImage[0] = 3;
        break;
      case PreImageType.P3b:
        basePreImage[0] = 4;
        break;
      case PreImageType.P1x:
        basePreImage[0] = 30;
        break;
      case PreImageType.P2x:
        basePreImage[0] = 31;
        break;
      case PreImageType.P4x:
        basePreImage[0] = 32;
        break;
      case PreImageType.P5x:
        basePreImage[0] = 33;
        break;
      default:
        throw new Error('Unknown index in getPreImage');
    }
    return basePreImage;
  }
}
export { HlTester, PreImageType };
