import HlCertTester from './HlCertTester';
import HlSecTester from './HLSecTester';
import HlSecTransferTester from './HlSecTransferTester';

export default class TFBrowserTester {
  private readonly hlCertTester: HlCertTester

  private readonly hlSecTester: HlSecTester

  private readonly hlSecTransferTester: HlSecTransferTester

  public getHlCertTester() {
    return this.hlCertTester;
  }

  public getHlSecTester() {
    return this.hlSecTester;
  }

  public getHlSecTransferTester() {
    return this.hlSecTransferTester;
  }

  constructor() {
    this.hlCertTester = new HlCertTester();
    this.hlSecTester = new HlSecTester();
    this.hlSecTransferTester = new HlSecTransferTester();
  }
}
