/* eslint-disable no-unused-vars */
import { WorkerParams, WorkerResult } from 'geeq_node_core/src/worker/interfaces';

export enum MessageType {
  init = 0,
  run = 1,
  exit = 2,
  result = 3,
}

export enum Workers {
  none = 0,
  getUatFlatten = 1,
  getUvtFlatten = 2,
  getNtlRefMerkleRootHash = 3,
  getNplRefMerkleRootHash = 4,
}

export interface InitMessage {
  worker: Workers;
}

export interface CallMessage {
  params: WorkerParams;
  method: string;
}

export interface ResultMessage {
  method: string;
  result: WorkerResult;
}

export type Message = InitMessage | CallMessage | ResultMessage;

export interface WorkerMessage {
  type: MessageType;
  data?: Message;
}
