import { ALB } from '@geeqdev/geeq_capnp_ts/generated/block.capnp';

import { Storage } from 'geeq_node_core/src/storage/interfaces';
import {
  ApplicationLayerBlockWrapper,
} from 'geeq_node_core/src/capnp/wrappers/application-layer-block';
import { GeeqNode } from 'geeq_node_core/src/actors/geeq-node';

export class AlbStorage implements Storage<ApplicationLayerBlockWrapper, ALB> {
  private cacheSize = 10000;

  private wrappers = new Array<ApplicationLayerBlockWrapper>();

  private readonly node: GeeqNode;

  constructor(node: GeeqNode) {
    this.node = node;
  }

  public async clearCache(): Promise<boolean> {
    this.wrappers = [];

    return true;
  }

  public async getCurrentBlock(): Promise<ALB> {
    return this.wrappers[0].getMessage();
  }

  public async getCurrentWrapper(): Promise<ApplicationLayerBlockWrapper> {
    return this.wrappers[0];
  }

  public async isEmpty(): Promise<boolean> {
    return this.wrappers.length === 0;
  }

  public async save(wrapper: ApplicationLayerBlockWrapper): Promise<boolean> {
    if (this.wrappers.length >= this.cacheSize) {
      this.wrappers.pop();
    }

    this.wrappers.unshift(wrapper);
    await this.node.addToLog('New ALB added to chain', wrapper);

    return true;
  }

  public setCacheSize(size: number): void {
    this.cacheSize = size;
  }
}
