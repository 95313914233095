/* eslint-disable class-methods-use-this */
import {
  Worker as WorkerInterfaces,
  WorkerParams,
  WorkerResult,
} from 'geeq_node_core/src/worker/interfaces';

// eslint-disable-next-line import/no-webpack-loader-syntax
import Runner from 'worker-loader!./runner';

import {
  MessageType,
  ResultMessage,
  WorkerMessage, Workers,
} from './interfaces';

export class WorkerThread implements WorkerInterfaces {
  private readonly runner: Runner;

  constructor(worker: Workers) {
    const runner = new Runner();

    const message: WorkerMessage = {
      type: MessageType.init,
      data: {
        worker,
      },
    };
    runner.postMessage(message);
    this.runner = runner;
  }

  public async call(
    method: string,
    params: WorkerParams,
  ): Promise<WorkerResult> {
    return new Promise<WorkerResult>((resolve) => {
      const message: WorkerMessage = {
        type: MessageType.run,
        data: {
          method,
          params,
        },
      };
      this.runner.postMessage(message);

      this.runner.onmessage = (event: MessageEvent) => {
        const msg = event.data as WorkerMessage;

        if (msg.type !== MessageType.result) {
          return;
        }

        const result = msg.data as ResultMessage;

        if (result.method !== method) {
          return;
        }

        resolve(result.result);
      };
    });
  }

  public async terminate(): Promise<number> {
    // return Promise.resolve(1);
    await this.runner.terminate();

    return 0;
  }
}
