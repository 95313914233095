/* eslint-disable no-unused-vars */
export enum UserType {
  bank = 'BANK',
  business = 'BUSINESS',
  creator = 'CREATOR',
  game = 'GAME',
  iotDevice = 'IOT_DEVICE',
  organization = 'ORGANIZATION',
  person = 'PERSON',
  platform = 'PLATFORM',
}
