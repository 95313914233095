/* eslint-disable no-shadow */
import { UserType } from '@/components/actors/user-type';

enum ContentType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  CONTENT = 'CONTENT',
}

enum ContentCreatorID {
  PUPPIES,
  SPORTS,
  YOGA,
  CHEF,
}

enum ProductID {
  PUPPY_VIDEO_1 = 'PV_1',
  PUPPY_VIDEO_2 = 'PV_2',
  PUPPY_VIDEO_3 = 'PV_3',
  PUPPY_VIDEO_4 = 'PV_4',

  WALLPAPER_1 = 'WP_1',
  WALLPAPER_2 = 'WP_2',
  WALLPAPER_3 = 'WP_3',
  WALLPAPER_4 = 'WP_4',

  SONG_1 = 'S_1',
  SONG_2 = 'S_2',
  SONG_3 = 'S_3',
  SONG_4 = 'S_4',

  RECIPE_1 = 'R1',
  RECIPE_2 = 'R2',
  RECIPE_3 = 'R3',
  RECIPE_4 = 'R4',
}

interface Product {
  id: string
  name: string
  buttonText: string
  content: string
  contentPrice: number
  type: ContentType
  paymentReceived: boolean
  coverImage?: string
  srcUrl?: string
}

/* eslint-disable @typescript-eslint/member-delimiter-style */
interface ContentCreator {
  id: string
  name: string
  storeName: string
  userName: string
  userType: UserType
  slogan: string
  logo: string
  greetingImg: string
  greetingText: string
  image: string
  credits: string
  products: Array<Product>
}

const ContentCreators: Array<ContentCreator> = [
  {
    id: ContentCreatorID.PUPPIES.toString(),
    name: 'Smile',
    storeName: 'happy-store',
    userName: 'The Happy Store',
    userType: UserType.creator,
    slogan:
      'Everyone needs to smile now and again.\n'
      + 'For just a few pennies, you can too.',
    logo: '/img/smiles-logo.png',
    greetingImg: '/img/credo1.png',
    greetingText: 'Pay & watch them play',
    image:
      'https://d1edejructrhm9.cloudfront.net/assets/Content%20Landing%20Page/Image%20-%20Yellow%20Lab%20Shaking%20Water.png',
    credits:
      'Video credits, clockwise from top left:\n'
      + 'HighWay_videography, Storyblocks, azgek, Motortion\n'
      + 'Envato Elements and Storyblocks\n',
    products: [
      {
        id: ProductID.PUPPY_VIDEO_1.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Happy%20Store%20Images/Image%20Dog%20on%20Leash%20in%20Park%20Smile.png',
        name: 'Puppy Video',
        buttonText: 'Watch For :price Geeq Pennies',
        content:
          'https://iframe.videodelivery.net/b93e7328949deaf238c7f8822017bd72',
        contentPrice: 4,
        type: ContentType.VIDEO,
        paymentReceived: false,
      },
      {
        id: ProductID.PUPPY_VIDEO_2.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Happy%20Store%20Images/Image%20Yellow%20Lab%20Shaking%20Water.png',
        name: 'Puppy Video',
        buttonText: 'Watch For :price Geeq Pennies',
        content:
          'https://iframe.videodelivery.net/7d6dc3bef0d65eb20648dc19655cb4cb',
        srcUrl: '',
        contentPrice: 8,
        type: ContentType.VIDEO,
        paymentReceived: false,
      },
      {
        id: ProductID.PUPPY_VIDEO_3.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Happy%20Store%20Images/Image%20Dog%20eating%20Fiat.png',
        name: 'Puppy Video',
        buttonText: 'Watch For :price Geeq Pennies',
        content:
          'https://iframe.videodelivery.net/9ec343a2e722066a6aea19b0f96ff6a2',
        srcUrl: '',
        contentPrice: 2,
        type: ContentType.VIDEO,
        paymentReceived: false,
      },
      {
        id: ProductID.PUPPY_VIDEO_4.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Happy%20Store%20Images/Image%20Corgi%20in%20Snow.png',
        name: 'Puppy Video',
        buttonText: 'Watch For :price Geeq Pennies',
        content:
          'https://iframe.videodelivery.net/558c07573bdc3058d5b7513a4562e0cf',
        srcUrl: '',
        contentPrice: 9,
        type: ContentType.VIDEO,
        paymentReceived: false,
      },
    ],
  },
  {
    id: ContentCreatorID.SPORTS.toString(),
    name: 'Enjoy',
    userName: 'Artcessible',
    storeName: 'wallpaper-store',
    userType: UserType.creator,
    slogan: `Art enriches.
    Refresh your senses in an instant.`,
    logo: '/img/wallpaper-logo.svg',
    greetingText: 'Expand your world for pennies',
    greetingImg: '/img/wallpaper-credo.png',
    image:
      'https://d1edejructrhm9.cloudfront.net/assets/Content%20Landing%20Page/Image%20-%20Swing%20and%20Landscape.jpg',
    credits:
      'Photography credits, clockwise from top left:\n'
      + 'Markus Spiske, Ryan Parker, Elena Mozhvilo, Cameron Venti \n'
      + 'unsplash.com',
    products: [
      {
        id: ProductID.WALLPAPER_1.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/cover/Markus%20Spiske%20-%20small.jpg',
        name: 'Art',
        buttonText: 'Download For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/Markus%20Spiske.jpg',
        contentPrice: 2,
        type: ContentType.IMAGE,
        paymentReceived: false,
      },
      {
        id: ProductID.WALLPAPER_2.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/cover/RYAN%20PARKER%20-%20small.jpg',
        name: 'Art',
        buttonText: 'Download For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/RYAN%20PARKER.jpg',
        contentPrice: 3,
        type: ContentType.IMAGE,
        paymentReceived: false,
      },
      {
        id: ProductID.WALLPAPER_3.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/cover/cameron%20venti%20-%20small.jpg',
        name: 'Art',
        buttonText: 'Download For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/cameron%20venti.jpg',
        contentPrice: 6,
        type: ContentType.IMAGE,
        paymentReceived: false,
      },
      {
        id: ProductID.WALLPAPER_4.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/cover/ELENA%20MOZHVILO%20-%20small.jpg',
        name: 'Art',
        buttonText: 'Download For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Artcessible%20Store/ELENA%20MOZHVILO.jpg',
        contentPrice: 7,
        type: ContentType.IMAGE,
        paymentReceived: false,
      },
    ],
  },
  {
    id: ContentCreatorID.YOGA.toString(),
    name: 'Relax',
    userName: 'Sound Share',
    storeName: 'music-store',
    userType: UserType.creator,
    slogan:
      'Get on your feet... or put your feet up.\n'
      + 'Resonate with your favourite musician with Geeq Pay.',
    image:
      'https://d1edejructrhm9.cloudfront.net/assets/Content%20Landing%20Page/Image%20-%20Spanish%20Guitar.jpg',
    logo: '/img/music_logo.svg',
    greetingImg: '/img/music-credo.png',
    greetingText: "Let's pay, play and sway",
    credits:
      'Photography credits, clockwise from top left:\n'
      + 'Adrian Korte, Zachary Smith, Claudette Bleijenberg, Davi Costa\n'
      + 'unsplash.com\n\n\n'
      + 'Music credits, clockwise from top left:\n'
      + 'Pyotr Ilyich Tchaikovsky, Beth Yap, William L. Newman, Stefan Dulov\n'
      + 'storyblocks.com',
    products: [
      {
        id: ProductID.SONG_1.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/cover/Image%20for%20Tchaikovsky%20-%20Vinyl%20Record%20-%20adrian-korte-5gn2soeAc40-unsplash.jpg',
        name: 'Music',
        buttonText: 'Play For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/audio/Audio%20for%20Tchaikovsky-reed-pipes-minute-Top-Left.mp3',
        contentPrice: 3,
        type: ContentType.AUDIO,
        paymentReceived: false,
      },
      {
        id: ProductID.SONG_2.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/cover/Image%20for%20Lights%20Concert%20zachary-smith-zorgErvL_Fs-unsplash.jpg',
        name: 'Music',
        buttonText: 'Play For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/audio/Audio%20for%20Lights%20Concert-gotta-feel-free-Top-Right.mp3',
        contentPrice: 6,
        type: ContentType.AUDIO,
        paymentReceived: false,
      },
      {
        id: ProductID.SONG_3.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/cover/Image%20for%20Bossa%20Nova%20Music%20-%20davi-costa-4lWIQ43CCmM-unsplash.jpg',
        name: 'Music',
        buttonText: 'Play For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/audio/Audio%20for%20Bossa%20Nova%20-%20Bottom%20Left.mp3',
        contentPrice: 16,
        type: ContentType.AUDIO,
        paymentReceived: false,
      },
      {
        id: ProductID.SONG_4.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/cover/Image%20for%20Spanish%20Guitar%20claudette-bleijenberg-yNui2IrAhiQ-unsplash.jpg',
        name: 'Music',
        buttonText: 'Play For :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/The%20Music%20Store/audio/Audio%20for%20Spanish-guitar-Bottom%20Right.mp3',
        contentPrice: 12,
        type: ContentType.AUDIO,
        paymentReceived: false,
      },
    ],
  },
  {
    id: ContentCreatorID.CHEF.toString(),
    name: 'Learn',
    userName: 'Recipenny',
    storeName: 'recipes-store',
    userType: UserType.creator,
    slogan:
      'Take the guesswork out of dinner.\n'
      + 'We’ve collected the best recipes in the world for you.',
    image:
      'https://d1edejructrhm9.cloudfront.net/assets/Content%20Landing%20Page/Image%20-%20Vegetables%20-%20franco-antonio-giovanella.jpg',
    logo: '/img/recipes-logo.svg',
    greetingImg: '/img/recipes-credo.png',
    greetingText: 'Click and cook',
    credits:
      'Photography credits, clockwise from top left:\n'
      + 'Mae Mu, Franco Antonio Giovanella, Louis Hansel, Ivan Torres.\n'
      + 'unsplash.com',
    products: [
      {
        id: ProductID.RECIPE_1.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/cover%20images/Image%20-%20Eggs%20-%20mae-Top%20Left.jpg',
        name: 'Recipe',
        buttonText: 'DIY FOR :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/recipe%20pdfs/Recipe%20-%20Eggs%20on%20Toast.pdf',
        contentPrice: 3,
        type: ContentType.CONTENT,
        paymentReceived: false,
      },
      {
        id: ProductID.RECIPE_2.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/cover%20images/Image%20-%20Vegetables%20-%20franco-antonio-giovanella-Top%20Right.jpg',
        name: 'Recipe',
        buttonText: 'DIY FOR :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/recipe%20pdfs/Recipe%20-%20Vegetables.pdf',
        contentPrice: 8,
        type: ContentType.CONTENT,
        paymentReceived: false,
      },
      {
        id: ProductID.RECIPE_3.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/cover%20images/Image%20-%20Pizza%20-%20ivan-torres-Bottom%20Left.jpg',
        name: 'Recipe',
        buttonText: 'DIY FOR :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/recipe%20pdfs/Recipe%20-%20Pizza%20at%20home%2C%20with%20love.pdf',
        contentPrice: 5,
        type: ContentType.CONTENT,
        paymentReceived: false,
      },
      {
        id: ProductID.RECIPE_4.toString(),
        coverImage:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/cover%20images/Image%20-%20Turnovers%20-%20louis-hansel-Bottom%20Right.jpg',
        name: 'Recipe',
        buttonText: 'DIY FOR :price Geeq Pennies',
        content: '',
        srcUrl:
          'https://d1edejructrhm9.cloudfront.net/assets/Recipenny%20Store/recipe%20pdfs/Recipe%20-%20Turnover%20-%20Cornish%20Pasties%20with%20Ready%20Made%20Pastry.pdf',
        contentPrice: 10,
        type: ContentType.CONTENT,
        paymentReceived: false,
      },
    ],
  },
];

// function findProductFromID(pID: ProductID): Product | undefined {
//   for (let i = 0; i < ContentCreators.length; i++) {
//     const contentCreator = ContentCreators[i]
//     for (let j = 0; j < contentCreator.products.length; j++) {
//       const product = contentCreator.products[j]
//       if (product.id === pID) {
//         return product
//       }
//     }
//   }
//   return undefined
// }

export {
  ContentCreators, ContentCreator, ProductID, ContentType,
};
