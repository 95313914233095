/* eslint-disable class-methods-use-this */
import { Message } from 'capnp-ts';
// Capnp messages
import { UVT, UvtData } from '@geeqdev/geeq_capnp_ts/generated/uvt.capnp';
import { GroupType } from '@geeqdev/geeq_capnp_ts/generated/messageTypes.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/generated/messageTxTypes.capnp';

// Message Wrappers
import { UvtWrapper } from 'geeq_node_core/src/capnp/wrappers/uvt';

// Actor related
import { GeeqUser } from 'geeq_node_core/src/actors/geeq-user';
import { ECKeys } from 'geeq_node_core/src/libraries/crypto/ec-keys';
import { Preimage } from 'geeq_node_core/src/libraries/preimage';
import { Subtle } from '@/models/subtle';
import { Serializer } from '@/models/serializer';
import { Dispatcher } from '@/models/worker/dispatcher';

// Utilities
// Peer modules

// #endregion

export default class UvtTester {
  async sendUvtMessage(
    txType: TxType,
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
    callbackFn: (uvtData: UvtData) => Promise<void>,
  ) {
    const message = new Message();
    const uvt = message.initRoot(UVT);

    const uvtData = uvt.initUvtData();
    uvtData.setGroupType(GroupType.GT_UNVER_VAL_USER_TX);
    uvtData.setBlockNumber(blockNumber + 1);
    uvtData.setChainNumber(chainNumber);
    const nodeIpAddress = sendingUser.getNodeIpByIndex(0);
    const nodeNumber = sendingUser.getNodeNumberByIp(nodeIpAddress);

    if (!nodeNumber) {
      throw new Error('Node Number null!');
    }

    uvtData.setNodeNumber(nodeNumber);
    uvtData.setTxType(txType);

    // Fill out the uvtData
    await callbackFn(uvtData);

    // Add signature
    const subtle = new Subtle();
    const ecKeys = new ECKeys(subtle);
    const preimage = new Preimage(subtle);
    const serializer = new Serializer();
    const dispatcher = new Dispatcher();
    const uvtWrapper = new UvtWrapper(
      ecKeys,
      preimage,
      serializer,
      dispatcher,
      message.toPackedArrayBuffer(),
      new Uint8Array(),
    );
    await uvtWrapper.signUVT();

    // DEBUG:
    // if (uvtData.isHlTxPayload()) {
    //   console.log('isHlTxPayload = true')
    //   if (uvtData.getHlTxPayload().isHlConsumeSecTx()) {
    //     console.log('isHlConsumeSecTx = true')
    //   } else if (uvtData.getHlTxPayload().isHlConsumeCertTx()) {
    //     console.log('isHlConsumeCertTx = true')
    //   } else if (uvtData.getHlTxPayload().isHlConsumeStandTx()) {
    //     console.log('isHlConsumeStandTx = true')
    //   } else {
    //     console.log('Some other HlTxPayload subtype')
    //   }
    // }

    // Send UVT the node
    await sendingUser.sendUVT(0, uvtWrapper.sourceBuffer);
  }
}
