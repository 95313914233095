import { createStore } from 'vuex';
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import { Simulator } from '@/models/Simulator';
import { SimStateUninitialized } from '@/models/SimulationState';
// import { NodeGroups } from '@/models/node-groups';
import { GeeqUserSim } from '@/components/actors/GeeqUserSim';
import { UserType } from '@/components/actors/user-type';

const NUM_ALT_USERS = 1;
const NUM_VISIBLE_ALT_USERS = 1;
export const SET_PUPPY_VIDEO_AMOUNT = 'SET_PUPPY_VIDEO_AMOUNT';
const GEEQ_CHAIN_NUMBER = 42;
// Initial balance, not counting the GBBs
const GEEQ_CHAIN_GEEQ_CORP_INITIAL_BALANCE = GeeqAmount.fromGeeqs(1000);
const GEEQ_CHAIN_USER_INITIAL_BALANCE = GeeqAmount.fromGeeqs(400);
const GEEQ_CHAIN_NODE_USER_INITIAL_BALANCE = GeeqAmount.fromGeeqs(300);
const GEEQ_CHAIN_ALT_USER_INITIAL_BALANCE = GeeqAmount.fromGeeqs(5000);
const GEEQ_GBB_AMOUNT = 200; // Good Behavior Bond amount

const TIME_MULTIPLIER = 2;
// const NVU_TIME_INTERVAL = 15;
const WAIT_FOR_TX_INTERVAL = 5; // How long to wait for UUTs before creating NVU
const WAIT_FOR_NVU_INTERVAL = 5; // HOW long to wait for NVUs before creating HTB

function geeqUserCmpFn(a: GeeqUserSim, b: GeeqUserSim): number {
  // const aUser = a as GeeqUserSim;
  // const bUser = b as GeeqUserSim;
  //
  // // Always bubble primary users to the top
  // if (aUser.getIsPrimary()) {
  //   return -1;
  // }
  //
  // if (bUser.getIsPrimary()) {
  //   return 1;
  // }
  //
  // // Next sort content creators to the top
  // if (aUser.getIsContentCreator()) {
  //   return -1;
  // }
  //
  // if (bUser.getIsContentCreator()) {
  //   return 1;
  // }
  //
  // // Use this comparison if we want to push the node users to the bottom
  // if (aUser.getIsNodeUser()) {
  //   return 1;
  // }
  //
  // if (bUser.getIsNodeUser()) {
  //   return -1;
  // }
  //
  // // Remaining users are in random order dictated by the randomly generated public account text
  // const s1 = aUser.getPublicAccount().shortFormat;
  // const s2 = bUser.getPublicAccount().shortFormat;

  // return s1.localeCompare(s2);
  return 1;
}

// Default # blocks to wait before resolving dishonest behavior.
// Set GeeqNode.BAD_BEHAVIOR_START_BLOCK to start bad behavior at a certain block.
const SPLIT_TIMER_TICKS = 3;

export default createStore({
  state: {
    // Current active ContentCreator
    currentContentCreator: null,

    // Mapping from ProductID to GeeqUsers
    productToGeeqUser: Object.create(null),

    // Global event listeners
    listenerAdded: {
      isAddedJukeboxListeners: false,
    },

    // Simulation state related to dishonest nodes
    splitTimer: SPLIT_TIMER_TICKS, // How many ticks to wait before resolving honesty
    // True, while a split detected and hasn't been resolved which node group is honest
    splitDetected: false,
    dishonestUser: null, // The dishonest user that steals Janes coins
    // nodeGroups: NodeGroups, // Contains list of sets of nodes that are cooperating

    // The user accounts that the primary account can see
    visiblePublicAccounts: new Array(0),

    // Simulation state
    restartSimOnMount: false,
    initialLoad: true,
    simulationState: SimStateUninitialized, // Simulator state

    // Fixed Settings
    numAltUsers: NUM_ALT_USERS,
    numVisibleAltUsers: NUM_VISIBLE_ALT_USERS,
    defaultAltUserName: 'User',

    // Settings
    primaryUserName: 'Funding Account',
    nodeUserName: 'Node Owner',
    timeMultiplier: TIME_MULTIPLIER,
    geeqInitialBalance: GEEQ_CHAIN_GEEQ_CORP_INITIAL_BALANCE,
    userType: UserType.bank,
    gBBAmount: GEEQ_GBB_AMOUNT, // Goood Behavior Bond amount

    // Primary User Settings
    userInitialBalance: GEEQ_CHAIN_ALT_USER_INITIAL_BALANCE,

    // Non-Primary User Settings
    altUserInitialBalance: GEEQ_CHAIN_USER_INITIAL_BALANCE,

    // Node Owner User Settings
    nodeUserInitialBalance: GEEQ_CHAIN_NODE_USER_INITIAL_BALANCE,

    // Constants
    geeqChainNumber: GEEQ_CHAIN_NUMBER,

    // Simulation clock tick
    clockTick: 0,

    // Time between generating NVUs
    //    nvuTimeInterval: NVU_TIME_INTERVAL,

    // Time interval to wait for UUTs before sending NVU to Hub
    waitForTxInterval: WAIT_FOR_TX_INTERVAL,

    // Time interval to wait for NVUs before sending HTB to non-hub nodes.
    waitForNvuInterval: WAIT_FOR_NVU_INTERVAL,

    // Geeq Corp
    theGeeqCorp: null, // TheGeeqCorp singleton

    // Geeq Users in simulation
    geeqUsers: new Array(0), // Array of GeeqUser objects

    // wallet user (Bob)
    walletUser: null,

    // Geeq Nodes in simulation
    geeqNodes: new Array(0), // Array of GeeqNode objects

    // Simulator
    theSimulator: new Simulator(), // Singleton that models the simulation

    // Event log
    eventLog: [],
    pappyVideoAmount: 4,
    isChromeBrowser: false,
    isMobileDevice: false,
    notEnoughTokens: false,
    availableTokens: 0,

    // Extension Exists
    isExtensionExists: false,
    // WalletUser data got from wallet
    walletUserData: null,
  },
  mutations: {
    clearEventLog(state) {
      state.eventLog.splice(0, state.eventLog.length);
    },
    updateCurrentContentCreator(state, contentCreator) {
      state.currentContentCreator = contentCreator;
    },
    updateRestartSim(state, doRestart) {
      state.restartSimOnMount = doRestart;
    },
    [SET_PUPPY_VIDEO_AMOUNT](state, payload) {
      state.pappyVideoAmount = payload;
      localStorage.setItem('VIDEO_PRICE', payload);
    },
    updateProductToGeeqUser(state, payload) {
      state.productToGeeqUser = payload;
    },
    addedJukeboxListeners(state) {
      state.listenerAdded.isAddedJukeboxListeners = true;
    },
    setWalletUser(state, user) {
      state.walletUser = user;
    },
    resetSplitTimer(state) {
      state.splitTimer = SPLIT_TIMER_TICKS;
    },
    decrementSplitTimer(state) {
      state.splitTimer -= 1;
    },
    updateNodeGroups(state, newState) {
      // state.nodeGroups = newState;
    },
    updateSplitDetected(state, newState) {
      state.splitDetected = newState;
    },
    updateDishonestUser(state, newState) {
      state.dishonestUser = newState;
    },
    addVisiblePublicAccount(state, visiblePublicAccount) {
      state.visiblePublicAccounts.push(visiblePublicAccount);
    },
    updateSimulationState(state, newState) {
      state.simulationState = newState;
    },
    addEventLog(state, eventLog) {
      state.eventLog = eventLog;
    },
    updateInitialLoad(state) {
      state.initialLoad = false;
    },
    // Clear out network partitions
    // Clear out the users
    // Clear out the users
    clearGeeqUsers(state) {
      state.geeqUsers = [];
    },
    // Sort geeq users
    sortGeeqUsers(state) {
      state.geeqUsers.sort(geeqUserCmpFn);
    },
    // Add Geeq Inc Singleton
    addGeeqCorp(state, newGeeqCorp) {
      state.theGeeqCorp = newGeeqCorp;
    },
    // Add user
    addGeeqUser(state, newUser) {
      state.geeqUsers.push(newUser);
    },
    // Clear out the nodes
    clearGeeqNodes(state) {
      state.geeqNodes = [];
    },
    // Add user
    addGeeqNode(state, newNode) {
      state.geeqNodes.push(newNode);
    },
    // Update Clock tick
    setClockTick(state, n) {
      state.clockTick = n;
    },
    incrementClockTick(state) {
      state.clockTick += 1;
    },
    setPrimaryUserName(state, userName) {
      state.primaryUserName = userName;
    },
    setTimeMultiplier(state, n) {
      state.timeMultiplier = n;
    },
    setUserInitialBalance(state, n) {
      state.userInitialBalance = n;
    },
    setGeeqInitialBalance(state, n) {
      state.geeqInitialBalance = n;
    },
    setUserType(state, userType) {
      state.userType = userType;
    },
    setIsChromeBrowser(state, isChrome) {
      state.isChromeBrowser = isChrome;
    },
    setIsMobileDevice(state, isMobile) {
      state.isMobileDevice = isMobile;
    },
    setNotEnoughTokens(state, notEnough) {
      state.notEnoughTokens = notEnough;
    },
    setAvailableTokensCount(state, count) {
      state.availableTokens = count;
    },
    setIsExtensionExists(state, isExtensionExists) {
      state.isExtensionExists = isExtensionExists;
    },
    setWalletUserData(state, data) {
      state.walletUserData = data;
    },
  },
  getters: {
    getPuppyVideoAmount: (state) => state.pappyVideoAmount,
    getIsChromeBrowser: (state) => state.isChromeBrowser,
    getIsExtensionExists: (state) => state.isExtensionExists,
    getIsMobileDevice: (state) => state.isMobileDevice,
    getAvailableTokens: (state) => state.availableTokens,
    getSimulationState: (state) => state.simulationState,
    getWalletUser: (state) => state.walletUser,
    getGeeqUsers: (state) => state.geeqUsers,
    gettheGeeqCorp: (state) => state.theGeeqCorp,
    getWalletUserData: (state) => state.walletUserData,
  },
});
