// #region imports
// Libraries
import { Message } from 'capnp-ts';
// Capnp messages
import {
  HlCreatePayload,
  HlConsumeSecTx,
  HlTxPayload,
  HlTransferSecTx,
  HlCreateSecTx,
  HlUnlockSecTx,
} from '@geeqdev/geeq_capnp_ts/generated/uvt.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/generated/messageTxTypes.capnp';

// Actor related
import { GeeqUser } from 'geeq_node_core/src/actors/geeq-user';
// Utilities
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import { HashDigest } from 'geeq_node_core/src/libraries/buffers/hash-digest';
import { PublicAccount } from 'geeq_node_core/src/libraries/buffers/public-account';

// Peer modules
import { Preimage } from 'geeq_node_core/src/libraries/preimage';

import { Subtle } from '@/models/subtle';
import { Serializer } from '@/models/serializer';

import { HlTester, PreImageType } from './HlTester';

export default class HlSecTransferTester extends HlTester {
  testPreImageSecP1: ArrayBuffer

  testPreImageSecP2: ArrayBuffer

  testPreImageSecP4: ArrayBuffer

  testPreImageSecP5: ArrayBuffer

  transferBlockNumRfd = 1000

  transferAcctNumSend: PublicAccount | null = null

  H4: HashDigest | null = null

  H5: HashDigest | null = null

  transferConsumeAcctNumReceive: PublicAccount | null = null

  constructor() {
    super();
    this.testPreImageSecP1 = this.getPreImage(PreImageType.P1x);
    this.testPreImageSecP2 = this.getPreImage(PreImageType.P2x);
    this.testPreImageSecP4 = this.getPreImage(PreImageType.P4x);
    this.testPreImageSecP5 = this.getPreImage(PreImageType.P5x);
  }

  async testHLCreateSec(
    sendingUser: GeeqUser,
    blockNumRfd: number,
    blockNumber: number,
    chainNumber: number,
  ) {
    // DEBUG: console.log('testHLCreateSec:', targetUser.userName)

    const callbackFn = async (hlCreateTxPayload: HlCreatePayload) => {
      // Set sec specific fields
      const hlCreaterSecTxMessage = new Message();
      const hlCreateSecTx = hlCreaterSecTxMessage.initRoot(HlCreateSecTx);

      // Lock with P2 in hlDigest2
      const hashDigest = new HashDigest(new Preimage(new Subtle()), new Serializer(), null);
      const hlDigest2 = await hashDigest.fromPreImage(this.testPreImageSecP2);
      if (hlDigest2.buffer == null) {
        throw new Error('hlDigest2 buffer is null in testHLCreateCert');
      }
      hlCreateSecTx.initHlDigest2(hlDigest2.buffer.byteLength);
      hlCreateSecTx.getHlDigest2().copyBuffer(hlDigest2.buffer);

      hlCreateTxPayload.setHlCreateSecTx(hlCreateSecTx);

      // DEBUG: console.log(hlCreateTxPayload.isHlCreateSecTx())
    };

    this.sendUvtHlCreateTxPayloadMessage(
      TxType.HL_CREATE_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      1, // nonce
      GeeqAmount.fromGeeqs(4), // amtTx
      blockNumRfd,
      this.testPreImageSecP1, // Lock with P1 in hlDigest1
      callbackFn,
    );
  }

  async testHLConsumeSec(
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // DEBUG: console.log('testHLConsumeSec')

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlCreaterSecTxMessage = new Message();
      const hlCreateSecTx = hlCreaterSecTxMessage.initRoot(HlConsumeSecTx);

      // Pass in acctNumReceive
      if (this.transferConsumeAcctNumReceive == null) {
        throw new Error('acctNumReceive is null in testHLConsumeSec (transfer)');
      }
      const acctNumReceive = this.transferConsumeAcctNumReceive;
      if (acctNumReceive == null) {
        throw new Error('acctNumReceive is null in testHLConsumeSec');
      }
      const acctNumReceiveBuffer = acctNumReceive.buffer;
      if (acctNumReceiveBuffer == null) {
        throw new Error('acctNumReceiveBuffer is null in testHLConsumeSec');
      }
      hlCreateSecTx.initAcctNumReceive(acctNumReceiveBuffer.byteLength);
      hlCreateSecTx.getAcctNumReceive().copyBuffer(acctNumReceiveBuffer);

      // Pass in preImage, which unlocks the HL
      const preImage = this.testPreImageSecP5;

      hlCreateSecTx.initHlPreImage(preImage.byteLength);
      hlCreateSecTx.getHlPreImage().copyBuffer(preImage);

      hlTxPayload.setHlConsumeSecTx(hlCreateSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_CONSUME_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }

  async testHLUnlockTransferSec(
    sendingUser: GeeqUser,
    targetUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // console.log('testHLUnlockTransferSec')

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlUnlockSecTxMessage = new Message();
      const hlUnlockSecTx = hlUnlockSecTxMessage.initRoot(HlUnlockSecTx);

      // New lock is Hash(blockNumRfd | acctNumSend | P2 | H4 | H5)
      // to be stored in HL account record
      this.transferAcctNumSend = await targetUser.getPublicAccountKey().getAccount();
      const hashDigest = new HashDigest(new Preimage(new Subtle()), new Serializer(), null);
      this.H4 = await hashDigest.fromPreImage(this.testPreImageSecP4);
      this.H5 = await hashDigest.fromPreImage(this.testPreImageSecP5);

      // Remember the blockBlockNumRfd and use it in the Consume UVT
      this.transferBlockNumRfd = blockNumber + 1000;

      const hlDigest1 = await hashDigest.fromSecTransferUvtFields(
        this.transferBlockNumRfd, // blockNumRfd
        this.transferAcctNumSend, // AcctNumSend
        this.testPreImageSecP2, // P2
        this.H4, // H4
        this.H5, // H5
      );
      if (hlDigest1.buffer == null) {
        throw new Error('hlDigest1 buffer is null in testHLCreateCert');
      }

      // Save new lock
      hlUnlockSecTx.initHlDigest1(hlDigest1.buffer.byteLength);
      hlUnlockSecTx.getHlDigest1().copyBuffer(hlDigest1.buffer);

      // Unlock HL Account Record with P1
      hlUnlockSecTx.initHlPreImage(this.testPreImageSecP1.byteLength);
      hlUnlockSecTx.getHlPreImage().copyBuffer(this.testPreImageSecP1);

      hlTxPayload.setHlUnlockSecTx(hlUnlockSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_UNLOCK_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }

  async testHLTransferSec(
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // console.log('testHLTransferSec:', sendingUser.userName)
    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlTransferSecTxMessage = new Message();
      const hlTransferSecTx = hlTransferSecTxMessage.initRoot(HlTransferSecTx);

      // blockNumRfd from unlock TX
      hlTransferSecTx.setBlockNumRfd(this.transferBlockNumRfd);

      // acctNumSend from unlock TX
      if (this.transferAcctNumSend == null) {
        throw new Error('acctNumSend is null in testHLTransferSec');
      }
      const acctNumSendBuffer = this.transferAcctNumSend.buffer;
      if (acctNumSendBuffer == null) {
        throw new Error('acctNumSendBuffer is null in testHLTransferSec');
      }
      hlTransferSecTx.initAcctNumSend(acctNumSendBuffer.byteLength);
      hlTransferSecTx.getAcctNumSend().copyBuffer(acctNumSendBuffer);

      // preImage
      hlTransferSecTx.initHlPreImage(this.testPreImageSecP2.byteLength);
      hlTransferSecTx.getHlPreImage().copyBuffer(this.testPreImageSecP2);

      // hlDigest1 = H4 from unlock Tx
      const hlDigest1 = this.H4;

      if (hlDigest1 == null) {
        throw new Error('hlDigest1 is null  in testHLCreateCert');
      }

      if (hlDigest1.buffer == null) {
        throw new Error('hlDigest1 buffer is null in testHLCreateCert');
      }

      hlTransferSecTx.initHlDigest1(hlDigest1.buffer.byteLength);
      hlTransferSecTx.getHlDigest1().copyBuffer(hlDigest1.buffer);

      // hlDigest2
      const hlDigest2 = this.H5;

      if (hlDigest2 == null) {
        throw new Error('hlDigest2 is null  in testHLCreateCert');
      }

      if (hlDigest2.buffer == null) {
        throw new Error('hlDigest2 buffer is null in testHLCreateCert');
      }

      hlTransferSecTx.initHlDigest2(hlDigest2.buffer.byteLength);
      hlTransferSecTx.getHlDigest2().copyBuffer(hlDigest2.buffer);

      hlTxPayload.setHlTransferSecTx(hlTransferSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_TRANSFER_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }

  // TODO: Share code with regular consume.  Just use different unlock preimage P4 vs P2
  async testHLUnlockTransferConsumeSec(
    sendingUser: GeeqUser,
    targetUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // DEBUG: console.log('testHLUnlockSec')

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlUnlockSecTxMessage = new Message();
      const hlUnlockSecTx = hlUnlockSecTxMessage.initRoot(HlUnlockSecTx);

      // Set hlDigest1 = Hash (P4 | acctNumReceive)
      this.transferConsumeAcctNumReceive = await targetUser.getPublicAccountKey().getAccount();
      const acctNumReceive = this.transferConsumeAcctNumReceive;

      if (acctNumReceive.buffer == null) {
        throw new Error('acctNumReceive is null in testHLUnlockSec');
      }

      const hashDigest = new HashDigest(new Preimage(new Subtle()), new Serializer(), null);
      const hlDigest1 = await hashDigest.fromSecUvtFields(
        this.testPreImageSecP5, // P5
        acctNumReceive.buffer, // AcctNumReceive
      );

      if (hlDigest1.buffer == null) {
        throw new Error('hlDigest1 buffer is null in testHLCreateCert');
      }

      hlUnlockSecTx.initHlDigest1(hlDigest1.buffer.byteLength);
      hlUnlockSecTx.getHlDigest1().copyBuffer(hlDigest1.buffer);

      // Use P4 to unlock HL Account Record
      hlUnlockSecTx.initHlPreImage(this.testPreImageSecP4.byteLength);
      hlUnlockSecTx.getHlPreImage().copyBuffer(this.testPreImageSecP4);

      hlTxPayload.setHlUnlockSecTx(hlUnlockSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_UNLOCK_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }
}
