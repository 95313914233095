// #region imports
// Libraries
import { Message } from 'capnp-ts';
// Capnp messages
import {
  HlCreatePayload,
  HlConsumeCertTx,
  HlTxPayload,
  HlCreateCertTx,
} from '@geeqdev/geeq_capnp_ts/generated/uvt.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/generated/messageTxTypes.capnp';

// Message Wrappers

// Actor related
import { GeeqUser } from 'geeq_node_core/src/actors/geeq-user';

// Utilities
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';

// Peer modules
import { HlTester, PreImageType } from './HlTester';

// #endregion

export default class HlCertTester extends HlTester {
  // Test related instance variables
  testPreImageCert: ArrayBuffer

  constructor() {
    super();
    this.testPreImageCert = this.getPreImage(PreImageType.P2);
  }

  async testHLConsumeCert(
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    console.log('testHLConsumeCert');

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set cert specific fields
      const hlCreaterCertTxMessage = new Message();
      const hlCreateCertTx = hlCreaterCertTxMessage.initRoot(HlConsumeCertTx);
      hlCreateCertTx.initHlPreImage(this.testPreImageCert.byteLength);

      // Pass in preImage, which unlocks the HL
      hlCreateCertTx.getHlPreImage().copyBuffer(this.testPreImageCert);

      hlTxPayload.setHlConsumeCertTx(hlCreateCertTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_CONSUME_CERT_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }

  async testHLCreateCert(
    sendingUser: GeeqUser,
    targetUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    console.log('testHLCreateCert:', targetUser.getUserName());

    const callbackFn = async (hlCreateTxPayload: HlCreatePayload) => {
      const acctNumReceive = targetUser.getPublicAccount();
      if (acctNumReceive.buffer == null) {
        throw new Error('acctNumReceive buffer is null in testHLCreateCert');
      }
      // Set cert specific fields
      const hlCreateCertTxMessage = new Message();
      const hlCreateCertTx = hlCreateCertTxMessage.initRoot(HlCreateCertTx);
      hlCreateCertTx.initAcctNumReceive(acctNumReceive.buffer.byteLength);
      hlCreateCertTx.getAcctNumReceive().copyBuffer(acctNumReceive.buffer);

      hlCreateTxPayload.setHlCreateCertTx(hlCreateCertTx);
      hlCreateTxPayload.initPubKeyUser(1).copyBuffer(new Uint8Array([0]));
    };

    await this.sendUvtHlCreateTxPayloadMessage(
      TxType.HL_CREATE_CERT_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      1, // nonce
      GeeqAmount.fromGeeqs(3), // amtTx
      1001, // blockNumRfd, TODO: figure out right value
      this.testPreImageCert,
      callbackFn,
    );
  }
}
