import {
  Subtle as SubtleInterface,
  ExportKeyFormat,
} from 'geeq_node_core/src/crypto/interfaces';

export class Subtle implements SubtleInterface {
  private readonly subtle: SubtleCrypto;

  constructor() {
    this.subtle = window.crypto.subtle;
  }

  public async digest(algorithm: AlgorithmIdentifier, data: ArrayBuffer): Promise<ArrayBuffer> {
    return this.subtle.digest(algorithm, data);
  }

  public async exportKey(format: ExportKeyFormat, key: CryptoKey): Promise<ArrayBuffer> {
    const result = await this.subtle.exportKey(format, key);

    return result as ArrayBuffer;
  }

  public async generateKey(
    algorithm: RsaHashedKeyGenParams | EcKeyGenParams,
    extractable: boolean,
    keyUsages: Array<KeyUsage>,
  ): Promise<CryptoKeyPair> {
    const result = await this.subtle.generateKey(algorithm, extractable, keyUsages);

    return result as CryptoKeyPair;
  }

  public async importKey(
    format: ExportKeyFormat,
    keyData: ArrayBuffer,
    algorithm: AlgorithmIdentifier
      | RsaHashedImportParams
      | EcKeyImportParams
      | HmacImportParams
      | AesKeyAlgorithm,
    extractable: boolean,
    keyUsages: Array<KeyUsage>,
  ): Promise<CryptoKey> {
    return this.subtle.importKey(
      format,
      keyData,
      algorithm,
      extractable,
      keyUsages,
    );
  }

  public async sign(
    algorithm: AlgorithmIdentifier | RsaPssParams | EcdsaParams,
    key: CryptoKey,
    data: ArrayBuffer,
  ): Promise<ArrayBuffer> {
    return this.subtle.sign(algorithm, key, data);
  }

  public async verify(
    algorithm: AlgorithmIdentifier | RsaPssParams | EcdsaParams,
    key: CryptoKey,
    signature: ArrayBuffer,
    data: ArrayBuffer,
  ): Promise<boolean> {
    return this.subtle.verify(
      algorithm,
      key,
      signature,
      data,
    );
  }
}
