/* eslint-disable class-methods-use-this */
import {
  Serializer as SerializerInterface,
} from 'geeq_node_core/src/serializers/interfaces';

export class Serializer implements SerializerInterface {
  public decode64(str: string): ArrayBuffer {
    return Uint8Array.from(atob(str), (symbol) => symbol.charCodeAt(0));
  }

  public encode64(buf: ArrayBuffer): string {
    return btoa(String.fromCharCode(...new Uint8Array(buf)));
  }

  public compare(src: ArrayBuffer, dest: ArrayBuffer): boolean {
    const srcString = btoa(
      String.fromCharCode(...new Uint8Array(src)),
    );
    const destString = btoa(
      String.fromCharCode(...new Uint8Array(dest)),
    );

    return srcString === destString;
  }
}
