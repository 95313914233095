/* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars */
import {
  Message as MessageBase,
} from 'capnp-ts';

import {
  CoinCreateTx,
  CoinFullTx,
  CoinPartTx,
  CoinTxPayload,
  HlCreatePayload,
  HlTxPayload,
  UVT,
  UvtData,
} from '@geeqdev/geeq_capnp_ts/dest/uvt.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/dest/messageTxTypes.capnp';

import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';

import { Crypto } from 'geeq_node_core/src/libraries/crypto';

import { Preimage } from 'geeq_node_core/src/libraries/preimage';

import { Transport, TransportEvent } from 'geeq_node_core/src/transport/interfaces';

import { GenericPublicKey } from 'geeq_node_core/src/libraries/public-keys/generic';
import {
  PublicAccountKeyNotInitializedError,
} from 'geeq_node_core/src/libraries/errors/public-account-key-not-initialized';
import {
  PublicAccountNotInitializedError,
} from 'geeq_node_core/src/libraries/errors/public-account-not-initialized';
import {
  TargetUserAccountBufferIsNullError,
} from 'geeq_node_core/src/libraries/errors/target-user-account-buffer-is-null';
import {
  UninitializedPublicKeyError,
} from 'geeq_node_core/src/libraries/errors/uninitialized-public-key';

import { Logger } from 'geeq_node_core/src/logger/interfaces/logger';
import { BusinessModules } from 'geeq_node_core/src/logger/interfaces/modules';
import { Dispatcher } from '@/models/worker/dispatcher';
import { PublicKeyBuffer } from 'geeq_node_core/src/capnp/buffers/public-key';
import { UserType } from '@/components/actors/user-type';
import { IdBuffer } from 'geeq_node_core/src/capnp/buffers/id';

export class GeeqUserSim {
  private readonly initialBalance: GeeqAmount;

  private readonly isVisible: boolean;

  private readonly userName: string;

  private readonly isPrimary: boolean;

  private readonly isContentCreator: boolean;

  private readonly isNodeUser: boolean;

  private readonly userType: UserType;

  private balance: GeeqAmount;

  private userTxNumber: number;

  private currentBlockNumber: number;

  /**
   * Public key typed as an AccountPublicKey
   */
  // private publicAccountKey?: AccountPublicKey;

  /**
   * Public Account that corresponds to the user public key
   */
  // private publicAccount?: PublicAccount;

  protected publicKey?: PublicKeyBuffer;

  protected readonly crypto: Crypto;

  private id: IdBuffer;

  constructor(
    ipAddress: string,
    crypto: Crypto,
    preimage: Preimage,
    transport: Transport,
    dispatcher: Dispatcher,
    geeqCorpPublicKey: PublicKeyBuffer,
    initialBalance: GeeqAmount,
    isVisible: boolean,
    userName: string,
    isPrimary: boolean,
    isContentCreator: boolean,
    isNodeUser: boolean,
    userType: UserType,
    logger: Logger,
  ) {
    this.crypto = crypto;

    this.initialBalance = initialBalance;
    this.balance = initialBalance;
    this.isVisible = isVisible;
    this.userName = userName;
    this.isPrimary = isPrimary;
    this.isContentCreator = isContentCreator;
    this.isNodeUser = isNodeUser;
    this.userTxNumber = 0;
    this.currentBlockNumber = 0;
    this.userType = userType;
  }

  public getPublicKey(): PublicKeyBuffer {
    if (!this.publicKey) {
      throw new Error('Public key is not initialized');
    }

    return this.publicKey;
  }

  public getId(): IdBuffer {
    if (!this.id) {
      throw new Error('id is not initialized');
    }

    return this.id;
  }

  public async initKeys(): Promise<void> {
    await this.crypto.generatePair();
    this.publicKey = this.crypto.getPublicKey();

    this.id = await this.crypto.hashBuffer(this.publicKey, 32);

    // if (this.keys.publicKey !== undefined) {
    //   this.publicKey = new PublicKeyBuffer(this.keys.publicKey);
    // }
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Convert public key into an AccountPublicKey
    // this.publicAccountKey = new AccountPublicKey(
    //   this.keys,
    //   this.serializer,
    //   this.publicKey.toArrayBuffer(),
    // );
    // this.publicAccount = await this.publicAccountKey.getAccount();
  }

  // public async replaceKeys(publicKey: CryptoKey): Promise<void> {
  //   await this.keys.setKeyPairFromImportedKeys(publicKey);
  //
  //   if (this.keys.publicKey !== undefined) {
  //     this.publicKey = new PublicKeyBuffer(this.keys.publicKey);
  //   }
  //
  //   if (!this.publicKey) {
  //     throw new UninitializedPublicKeyError();
  //   }
  //
  //   this.publicAccountKey = new AccountPublicKey(
  //     this.keys,
  //     this.serializer,
  //     this.publicKey.toArrayBuffer(),
  //   );
  //   this.publicAccount = await this.publicAccountKey.getAccount();
  // }

  /**
   * Hack for now, since we are getting the node list from the
   * Genesis block instead of asking a Node
   */
  // public get targetUserList(): Array<string> {
  //   if (!this.genesisBlockWrapper) {
  //     return [];
  //   }
  //
  //   // const { vllJSON } = this.genesisBlockWrapper;
  //   // const { coinAccountRecords } = vllJSON;
  //   // const publicKeyList: Array<string> = [];
  //   // coinAccountRecords.forEach(
  //   //   (coinAccountRecord: { [k: string]: unknown }) => {
  //   //     console.log(coinAccountRecord.publicKey);
  //   //     const publicKey: string = coinAccountRecord.publicKey as string;
  //   //     publicKeyList.push(publicKey);
  //   //   },
  //   // );
  //   // return publicKeyList;
  //
  //   return this
  //     .genesisBlockWrapper
  //     .vllJSON
  //     .coinAccountRecords
  //     .map((record) => record.pub)
  // }

  public getInitialBalance(): GeeqAmount {
    return this.initialBalance;
  }

  public getBalance(): GeeqAmount {
    return this.balance;
  }

  public setBalance(value: GeeqAmount): void {
    this.balance = value;
  }

  public getIsVisible(): boolean {
    return this.isVisible;
  }

  public getUserName(): string {
    return this.userName;
  }

  public getIsPrimary(): boolean {
    return this.isPrimary;
  }

  public getIsContentCreator(): boolean {
    return this.isContentCreator;
  }

  public getIsNodeUser(): boolean {
    return this.isNodeUser;
  }

  // public getPublicAccountKey(): AccountPublicKey {
  //   if (!this.publicAccountKey) {
  //     throw new PublicAccountKeyNotInitializedError();
  //   }
  //
  //   return this.publicAccountKey;
  // }
  //
  // public getPublicAccount(): PublicAccount {
  //   if (!this.publicAccount) {
  //     throw new PublicAccountNotInitializedError();
  //   }
  //
  //   return this.publicAccount;
  // }

  public getUserType(): UserType {
    return this.userType;
  }

  // public async consumeBearerTokens(
  //   nodeIndex: number,
  //   targetUserAccount: PublicAccount,
  //   preImages: string[],
  // ): Promise<unknown> {
  //   return Promise.all(
  //     preImages
  //       .map((preImage) => this.sendBtConsumeStandTransaction(
  //         nodeIndex,
  //         targetUserAccount,
  //         preImage,
  //       )),
  //   );
  // }

  public async sendUVT(nodeIndex: number, transaction: ArrayBuffer): Promise<boolean> {
    // TODO:  Fix this when we maintain the latest known VLL
    // const ip = this.getNodeIpAddr(nodeIndex);
    //
    // if (ip === '') {
    //   throw new EmptyIpAddressError();
    // }
    //
    // if (!this.transport) {
    //   throw new UninitializedTransportLayerError();
    // }
    //
    // return this.transport.send(
    //   ip,
    //   TransportEvent.uvt,
    //   {
    //     transaction,
    //   },
    // );
    return true;
  }

  public async testHLConsumeCert(): Promise<void> {
    // this.geeqUserLogger.info({}, 'testHLConsumeCert');
  }

  public async testProxyCreateRecord(
    blockNumber: number,
    chainNumber: number,
  ): Promise<boolean> {
    return false;
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    // const uvtData = uvt.initUvtData();
    // uvtData.setGroupType(GroupType.GT_UNVER_VAL_USER_TX);
    // uvtData.setBlockNumber(blockNumber + 1);
    // uvtData.setChainNumber(chainNumber);
    //
    // uvtData
    //   .setNodeNumber(uvtData.getNodeNumber());
    // uvtData.setTxType(TxType.PROXY_CREATE_MULTISIG_TX);
    //
    // const proxyCreatePayload = uvtData.initProxyCreatePayload();
    // proxyCreatePayload.setNonce(1);
    // proxyCreatePayload.setAmount(GeeqAmount.fromGeeqs(1).toUint64());
    // proxyCreatePayload.setCount(1);
    // proxyCreatePayload.initAcctNumMultiUser(1);
    //
    // const user = proxyCreatePayload.getAcctNumMultiUser().get(0);
    // user.initUser();
    // // user.getUser().copyBuffer(new Uint8Array([0, 1, 2, 3, 4, 5]));
    // // this.geeqUserLogger.info({ data: proxyCreatePayload.getAcctNumMultiUser().get(0) });
    //
    // proxyCreatePayload
    //   .initAcctNumVal(10)
    //   .copyBuffer(new Uint8Array([0, 1, 1, 1, 1, 1, 1, 1, 1, 1]));
    // proxyCreatePayload.initPubKeyUser(1).copyBuffer(new Uint8Array([0]));
    // proxyCreatePayload.initMultiSigPayload().setSigThresholdNum(0);
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // proxyCreatePayload
    //   .initPubKeyUser(this.publicKey.getBuffer().byteLength)
    //   .copyBuffer(this.publicKey.getBuffer());
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Send UVT the node
    // return this.sendUVT(0, uvtWrapper.sourceBuffer);
  }

  async testProxyCreateMultiUser(
    blockNumber: number,
    chainNumber: number,
  ): Promise<boolean> {
    return false;

    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    // const uvtData = uvt.initUvtData();
    // uvtData.setGroupType(GroupType.GT_UNVER_VAL_USER_TX);
    // uvtData.setBlockNumber(blockNumber + 1);
    // uvtData.setChainNumber(chainNumber);
    //
    // uvtData
    //   .setNodeNumber(uvtData.getNodeNumber());
    // uvtData.setTxType(TxType.PROXY_ADD_TX);
    //
    // const proxyAddPayload = uvtData.initProxyAddPayload();
    // proxyAddPayload.setNonce(1);
    // proxyAddPayload.initAcctNumMultiUser(5);
    //
    // const user = proxyAddPayload.getAcctNumMultiUser();
    // user.copyBuffer(new Uint8Array([9, 9, 9, 9, 9, 9]));
    // // this.geeqUserLogger.info({ data: proxyAddPayload.getAcctNumMultiUser().get(0) });
    //
    // proxyAddPayload
    //   .initAcctNumVal(10)
    //   .copyBuffer(new Uint8Array([0, 1, 1, 1, 1, 1, 1, 1, 1, 1]));
    // proxyAddPayload.initPubKeyUser(1).copyBuffer(new Uint8Array([0]));
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // proxyAddPayload
    //   .initPubKeyUser(this.publicKey.getBuffer().byteLength)
    //   .copyBuffer(this.publicKey.getBuffer());
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Send UVT the node
    // return this.sendUVT(0, uvtWrapper.sourceBuffer);
  }

  public async testProxyRemoveMultiUser(
    blockNumber: number,
    chainNumber: number,
  ): Promise<boolean> {
    return false;

    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    // const uvtData = uvt.initUvtData();
    // uvtData.setGroupType(GroupType.GT_UNVER_VAL_USER_TX);
    // uvtData.setBlockNumber(blockNumber + 1);
    // uvtData.setChainNumber(chainNumber);
    //
    // uvtData
    //   .setNodeNumber(uvtData.getNodeNumber());
    // uvtData.setTxType(TxType.PROXY_REMOVE_TX);
    //
    // const proxyRemovePayload = uvtData.initProxyRemovePayload();
    // proxyRemovePayload.setNonce(1);
    // proxyRemovePayload.initAcctNumMultiUser(5);
    //
    // const user = proxyRemovePayload.getAcctNumMultiUser();
    // user.copyBuffer(new Uint8Array([9, 9, 9, 9, 9, 9]));
    // // this.geeqUserLogger.info({ data: proxyRemovePayload.getAcctNumMultiUser().get(0) });
    //
    // proxyRemovePayload
    //   .initAcctNumVal(10)
    //   .copyBuffer(new Uint8Array([0, 1, 1, 1, 1, 1, 1, 1, 1, 1]));
    // proxyRemovePayload.initPubKeyUser(1).copyBuffer(new Uint8Array([0]));
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // proxyRemovePayload
    //   .initPubKeyUser(this.publicKey.getBuffer().byteLength)
    //   .copyBuffer(this.publicKey.getBuffer());
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Send UVT the node
    // return this.sendUVT(0, uvtWrapper.sourceBuffer);
  }

  public async testProxyRemoveRecord(
    blockNumber: number,
    chainNumber: number,
  ): Promise<boolean> {
    return false;

    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    // const uvtData = uvt.initUvtData();
    // uvtData.setGroupType(GroupType.GT_UNVER_VAL_USER_TX);
    // uvtData.setBlockNumber(blockNumber + 1);
    // uvtData.setChainNumber(chainNumber);
    //
    // uvtData
    //   .setNodeNumber(uvtData.getNodeNumber());
    // uvtData.setTxType(TxType.PROXY_DELETE_REC_TX);
    //
    // const muDeletePayload = uvtData.initProxyDeletePayload();
    // muDeletePayload.setNonce(1);
    // muDeletePayload
    //   .initAcctNumVal(10)
    //   .copyBuffer(new Uint8Array([0, 1, 1, 1, 1, 1, 1, 1, 1, 1]));
    // muDeletePayload.initPubKeyUser(1).copyBuffer(new Uint8Array([0]));
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // muDeletePayload
    //   .initPubKeyUser(this.publicKey.getBuffer().byteLength)
    //   .copyBuffer(this.publicKey.getBuffer());
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Send UVT the node
    // return this.sendUVT(0, uvtWrapper.sourceBuffer);
  }

  public async testCreateNotaryUAT(
    ip: string,
    hash: string,
    meta: Array<string>,
    blockNumber: number,
    chainNumber: number,
  ): Promise<boolean> {
    return false;
    // // Create UAT
    // const message = new MessageBase();
    // const uat = message.initRoot(UAT);
    // const uatData = uat.getUatData();
    //
    // uatData.setGroupType(GroupType.GT_UNVER_APP_USER_TX); // groupType field
    // uatData.setBlockNum(blockNumber + 1); // blockNum field
    // uatData.setChainNum(chainNumber); // chainNum field
    // const nodePublicKey = this.getNodePublicKey(0); // TODO: convert to nodeNum field
    //
    // if (!nodePublicKey) {
    //   throw new NodePublicKeyUndefinedError();
    // }
    //
    // uatData.setTxType(TxType.APP_NOTARY_COMMIT_TX); // txType field
    // uatData.setNonce(1); // nounce field
    //
    // uatData.setAmtTx(Uint64.fromNumber(0)); // amtTx field
    // uatData.setFeeSource(UatDataFeeSource.FEE_COIN); // feeSource field
    // uatData.initAcctNumVal(1).copyBuffer(new Uint8Array([0])); // acctNumVal field
    // uatData.initAcctNumApp(1).copyBuffer(new Uint8Array([0])); // acctNumApp field
    //
    // uatData.initNodePublicKey(nodePublicKey.getBuffer().byteLength);
    // uatData.getNodePublicKey().copyBuffer(nodePublicKey.getBuffer());
    //
    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // pubKeyUser field
    // uatData.initPubKeyUser(this.publicKey.getBuffer().byteLength);
    // const senderPublicKeyField = uatData.getPubKeyUser();
    // senderPublicKeyField.copyBuffer(this.publicKey.getBuffer());
    //
    // const [refHash, payloadBuffer] = await this.getTestNotaryRefHash(
    //   hash,
    //   meta,
    // );
    //
    // uatData.initRefHash(refHash.byteLength); // refHash field
    // uatData.getRefHash().copyBuffer(refHash);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUatFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uatWrapper = new UatWrapper(
    //   this.keys,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uatWrapper.signUAT(); // sigUser field
    //
    // // Send UAT the node
    // return this.transport.send(
    //   ip,
    //   TransportEvent.uat,
    //   uatWrapper.sourceBuffer,
    //   payloadBuffer,
    // );
  }

  public async getTestNotaryRefHash(
    hash: string,
    meta: Array<string>,
  ): Promise<[ArrayBuffer, ArrayBuffer]> {
    return [new Uint8Array(0), new Uint8Array(0)];
    // // Create payload
    // const payloadMessage = new MessageBase();
    // const payload = payloadMessage.initRoot(AppPayload);
    // payload.getData().initNotaryPayload();
    //
    // const notary = payload.getData().getNotaryPayload();
    // const hashBuffer = this.serializer.decode64(hash);
    // notary
    //   .initAppCommitHash(hashBuffer.byteLength)
    //   .copyBuffer(hashBuffer);
    // notary.initAppLayerMetadata(meta.length);
    // meta.forEach((data, index) => {
    //   const metaBuffer = this.serializer.decode64(data);
    //   notary
    //     .getAppLayerMetadata()
    //     .get(index)
    //     .initData(metaBuffer.byteLength)
    //     .copyBuffer(metaBuffer);
    // });
    //
    // // TODO: Hash should be that of the flattened payload
    // const payloadBuffer = payloadMessage.toPackedArrayBuffer();
    // const notaryPayload = new UatPayloadWrapper(this.keys, payloadBuffer);
    // const refHash = await notaryPayload.computeRefHash();
    //
    // return [refHash, payloadBuffer];
  }

  /**
   * For each hash received, send a Bearer Token Create transaction to the specified node.
   */
  public async createStandardBearerTokens(
    nodeIndex: number,
    hashes: string[],
    denomination: GeeqAmount,
  ): Promise<void> {
    // this.geeqUserLogger.info(
    //   {
    //     hashes,
    //   },
    //   'Bearer Token Hashes received by GeeqUser',
    // );
    hashes.forEach((hash) => {
      this.createStandardBearerToken(nodeIndex, hash, denomination);
    });
  }

  /**
   * Send COIN_CREATE_TX (Micro)
   */
  public async sendCoinCreateTransaction(
    nodeIp: string,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
    blockNumRfd: number,
  ): Promise<boolean> {
    return false;

    // // Create new block data
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    //
    // // Obtain node number
    // const nodeIndex = this.getNodeIndexByIP(nodeIp);
    // const nodeNumber = uvt.getUvtData().getNodeNumber();
    // if (!nodeNumber) {
    //   throw new MissingNodeNumError();
    // }
    // // TODO: Need to get
    // const targetBlockNumber = 0;
    // const userTxNumber = this.nextUserTxNumber(targetBlockNumber);
    //
    // const uvtData: UvtData = this.createUvtCoinCreateTxData(
    //   this.targetChainNumber,
    //   targetBlockNumber,
    //   userTxNumber,
    //   nodeNumber,
    //   targetUserAccount,
    //   amount,
    //   blockNumRfd,
    // );
    // // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // await this.addToLog(
    //   `UVT (COIN_CREATE_TX) generated (destination nodeIndex=${nodeIndex},
    //   recipient account = ${targetUserAccount.shortFormat}})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return this.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }

  /**
   * Send COIN_FULL_TX
   */
  public async sendCoinFullTransaction(
    nodeIp: string,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
  ): Promise<boolean> {
    return false;
    // // Create new block data
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    //
    // // Obtain node public key
    // const nodeIndex = this.getNodeIndexByIP(nodeIp);
    //
    // // TODO: Need to get
    // const targetBlockNumber = 0;
    // const userTxNumber = this.nextUserTxNumber(targetBlockNumber);
    //
    // const uvtData: UvtData = this.createUvtCoinFullTxData(
    //   this.targetChainNumber,
    //   targetBlockNumber,
    //   userTxNumber,
    //   targetUserAccount,
    // );
    //   // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // await this.addToLog(
    //   `UVT (COIN_FULL_TX) generated (destination nodeIndex=${nodeIndex},
    //   recipient account = ${targetUserAccount.shortFormat}})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return this.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }

  public async getEventModule(): Promise<BusinessModules> {
    return BusinessModules.user;
  }

  /**
   * Send COIN_PART_TX
   */
  public async sendCoinPartTransaction(
    nodeIp: string,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
  ): Promise<boolean> {
    return false;

    // // Create new block data
    // console.log('ASDDDDD');
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    //
    // const nodeIndex = this.getNodeIndexByIP(nodeIp);
    // const nodeNumber = this.getNodeNumberByIp(nodeIp);
    // console.log(21212121, nodeNumber);
    // if (!nodeNumber) {
    //   throw new MissingNodeNumError();
    // }
    //
    // // TODO: Need to get
    // const targetBlockNumber = 0;
    // const userTxNumber = this.nextUserTxNumber(targetBlockNumber);
    //
    // const uvtData: UvtData = this.createUvtCoinTxPartData(
    //   this.targetChainNumber,
    //   targetBlockNumber,
    //   userTxNumber,
    //   nodeNumber,
    //   targetUserAccount,
    //   amount,
    // );
    //   // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // await this.addToLog(
    //   `UVT (COIN_PART_TX) generated (destination nodeIndex=${nodeIndex}, recipient account = ${
    //     targetUserAccount.shortFormat
    //   }, amt=${amount.format()})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return this.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }

  private async sendBtConsumeStandTransaction(
    nodeIndex: number,
    // targetUserAccount: PublicAccount,
    preImage: string,
  ): Promise<boolean> {
    return false;

    // // Debug find hash
    // // const preImageBuffer = Serializer.decode64(preImage);
    // // const preImageHashBuffer = await this.preimage.getPreImageHash(preImageBuffer);
    // // const preImageHash = Serializer.encode64(preImageHashBuffer);
    //
    // // this.geeqUserLogger.info({}, `SendBtConsumeStandTransaction (preImage): ${preImage}`);
    // // this.geeqUserLogger.info(
    // //   {},
    // //   `SendBtConsumeStandTransaction (preImageHash): ${preImageHash}`,
    // // );
    //
    // // Create new block data
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    //
    // const uvtData = GeeqUserSim.createUvtHlConsumeStandTxData(
    //   this.targetChainNumber,
    //   0,
    //   this.serializer,
    //   uvt.getUvtData().getNodeNumber(),
    //   targetUserAccount,
    //   preImage,
    // );
    // // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // const preImage1 = uvt
    //   .getUvtData()
    //   .getHlTxPayload()
    //   .getHlConsumeStandTx()
    //   .getHlPreImage()
    //   .toArrayBuffer();
    // await this.addToLog(
    //   `UVT (CONSUME_STAND_TX_PART) generated (destination nodeIndex=${nodeIndex},
    //   recipient account = ${
    //     targetUserAccount.shortFormat
    //   }, preImage1=${Formatter.buf2hex(preImage1)})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return this.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }

  public nextUserTxNumber(blockNumber: number): number {
    if (blockNumber === this.currentBlockNumber) {
      this.userTxNumber += 1;
    } else {
      this.currentBlockNumber = blockNumber;
      this.userTxNumber = 0;
    }

    return this.userTxNumber;
  }

  /**
   * For hash received, send a Bearer Token Create transaction to the specified node
   */
  protected async createStandardBearerToken(
    nodeIndex: number,
    hash: string,
    amount: GeeqAmount,
  ): Promise<boolean> {
    return false;

    // const hashBuffer = this.serializer.decode64(hash) as Uint8Array;
    //
    // // Create new block data
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    //
    // // TODO: Need to get
    // const targetBlockNumber = 0;
    // const userTxNumber = this.nextUserTxNumber(targetBlockNumber);
    //
    // const uvtData = this.createUvtHlCreateStandTxData(
    //   this.targetChainNumber,
    //   targetBlockNumber,
    //   userTxNumber,
    //   hashBuffer,
    //   amount,
    // );
    // // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   this.serializer,
    //   message.toPackedArrayBuffer(),
    // );
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   this.keys,
    //   this.preimage,
    //   this.serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // await this.addToLog(
    //   `UVT (HL_CREATE_STAND_TX) generated (destination nodeIndex=${nodeIndex},
    //   hash = ${`${hash.slice(
    //     0,
    //     6,
    //   )}...`}, amt=${amount.format()})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return this.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }

  private async sendUAT(
    nodeIndex: number,
    transaction: ArrayBuffer,
    payload: ArrayBuffer,
  ): Promise<boolean> {
    // TODO:  Fix this when we maintain the latest known VLL
    // const ip = this.getNodeIpAddr(nodeIndex);
    //
    // if (ip === '') {
    //   throw new EmptyIpAddressError();
    // }
    //
    // if (!this.transport) {
    //   throw new UninitializedTransportLayerError();
    // }
    //
    // return this.transport.send(
    //   ip,
    //   TransportEvent.uat,
    //   {
    //     transaction,
    //     payload,
    //   },
    // );
    return true;
  }

  private createUvtHlCreateStandTxData(
    chainNumber: number,
    blockNumber: number,
    nonce: number,
    hash: Uint8Array,
    amount: GeeqAmount,
  ): UvtData {
    const innerMessage = new MessageBase();
    const uvtData = innerMessage.initRoot(UvtData);

    const createHlStandPayloadMessage = new MessageBase();
    const createHlStandPayload = createHlStandPayloadMessage.initRoot(
      HlCreatePayload,
    );

    createHlStandPayload.setNonce(nonce);
    createHlStandPayload.setAmtTx(amount.toUint64());
    // TODO: add better default
    // TODO: Set real block numberm not hard-coded test number
    createHlStandPayload.setBlockNumRfd(5);

    createHlStandPayload.initHlDigest1(hash.byteLength);
    const field = createHlStandPayload.getHlDigest1();
    field.copyBuffer(hash);

    // Initialize header
    uvtData
      .setNodeNumber(uvtData.getNodeNumber());
    uvtData.setTxType(TxType.HL_CREATE_STAND_TX);

    uvtData.setChainNumber(chainNumber);
    uvtData.setBlockNumber(blockNumber);
    createHlStandPayload.setNonce(nonce);

    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // createHlStandPayload.initPubKeyUser(this.publicKey.length);
    // const senderPublicKeyField = createHlStandPayload.getPubKeyUser();
    // senderPublicKeyField.copyBuffer(this.publicKey.toArrayBuffer());

    uvtData.initCoinTxPayload();
    uvtData.setHlCreatePayload(createHlStandPayload);

    return uvtData;
  }

  public createUvtCoinTxPartData(
    chainNumber: number,
    blockNumber: number,
    txNumber: number,
    nodeNumber: number,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
    sendingUserAccount?: GenericPublicKey | undefined,
  ): UvtData {
    const innerMessage = new MessageBase();
    const uvtData = innerMessage.initRoot(UvtData);

    const coinTxPayloadMessage = new MessageBase();
    const coinTxPayload = coinTxPayloadMessage.initRoot(CoinTxPayload);

    const coinPartTxMessage = new MessageBase();
    const coinPartTx = coinPartTxMessage.initRoot(CoinPartTx);

    coinPartTx.setNonce(txNumber);
    coinPartTx.setAmtTx(amount.toUint64());

    coinTxPayload.initCoinPartTx();
    coinTxPayload.setCoinPartTx(coinPartTx);

    // Initialize header
    uvtData
      .setNodeNumber(uvtData.getNodeNumber());

    uvtData.setChainNumber(chainNumber);
    uvtData.setBlockNumber(blockNumber);
    uvtData.setTxType(TxType.COIN_PART_TX);

    if (!sendingUserAccount) {
      throw new UninitializedPublicKeyError();
    }

    // Save my public key
    coinTxPayload.initPublicKeyUser(sendingUserAccount.getBuffer().byteLength);
    const senderPublicKeyField = coinTxPayload.getPublicKeyUser();
    senderPublicKeyField.copyBuffer(sendingUserAccount.getBuffer());

    // Save my target public key
    // const targetUserAccountBuffer = targetUserAccount.buffer;

    // if (!targetUserAccountBuffer) {
    //   throw new TargetUserAccountBufferIsNullError();
    // }

    // coinTxPayload.initAcctNumReceive(targetUserAccountBuffer.byteLength);
    // const receiverAccountField = coinTxPayload.getAcctNumReceive();
    // receiverAccountField.copyBuffer(targetUserAccountBuffer);

    uvtData.initCoinTxPayload();
    uvtData.setCoinTxPayload(coinTxPayload);

    return uvtData;
  }

  public createUvtCoinFullTxData(
    chainNumber: number,
    blockNumber: number,
    txNumber: number,
    // targetUserAccount: PublicAccount,
  ): UvtData {
    const innerMessage = new MessageBase();
    const uvtData = innerMessage.initRoot(UvtData);

    const coinTxPayloadMessage = new MessageBase();
    const coinTxPayload = coinTxPayloadMessage.initRoot(CoinTxPayload);

    const coinFullTxMessage = new MessageBase();
    const coinFullTx = coinFullTxMessage.initRoot(CoinFullTx);

    coinFullTx.setNonce(txNumber);

    coinTxPayload.initCoinPartTx();
    coinTxPayload.setCoinFullTx(coinFullTx);

    // Initialize header
    uvtData
      .setNodeNumber(uvtData.getNodeNumber());

    uvtData.setChainNumber(chainNumber);
    uvtData.setBlockNumber(blockNumber);
    uvtData.setTxType(TxType.COIN_FULL_TX);

    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // coinTxPayload.initPublicKeyUser(this.publicKey.length);
    // const senderPublicKeyField = coinTxPayload.getPublicKeyUser();
    // senderPublicKeyField.copyBuffer(this.publicKey.toArrayBuffer());

    // Save my target public key
    // const targetUserAccountBuffer = targetUserAccount.buffer;
    //
    // if (!targetUserAccountBuffer) {
    //   throw new TargetUserAccountBufferIsNullError();
    // }
    //
    // coinTxPayload.initAcctNumReceive(targetUserAccountBuffer.byteLength);
    // const receiverAccountField = coinTxPayload.getAcctNumReceive();
    // receiverAccountField.copyBuffer(targetUserAccountBuffer);

    uvtData.initCoinTxPayload();
    uvtData.setCoinTxPayload(coinTxPayload);

    return uvtData;
  }

  public createUvtCoinCreateTxData(
    chainNumber: number,
    blockNumber: number,
    txNumber: number,
    nodeNumber: number,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
    blockNumRfd: number,
  ): UvtData {
    const innerMessage = new MessageBase();
    const uvtData = innerMessage.initRoot(UvtData);

    const coinTxPayloadMessage = new MessageBase();
    const coinTxPayload = coinTxPayloadMessage.initRoot(CoinTxPayload);

    const coinCreateTxMessage = new MessageBase();
    const coinCreateTx = coinCreateTxMessage.initRoot(CoinCreateTx);

    coinCreateTx.setAmtTx(amount.toUint64());
    coinCreateTx.setBlockNumRfd(blockNumRfd);

    coinTxPayload.initCoinPartTx();
    coinTxPayload.setCoinCreateTx(coinCreateTx);

    // Initialize header
    uvtData
      .setNodeNumber(uvtData.getNodeNumber());

    uvtData.setChainNumber(chainNumber);
    uvtData.setBlockNumber(blockNumber);
    uvtData.setTxType(TxType.COIN_CREATE_TX);

    // if (!this.publicKey) {
    //   throw new UninitializedPublicKeyError();
    // }
    //
    // // Save my public key
    // coinTxPayload.initPublicKeyUser(this.publicKey.length);
    // const senderPublicKeyField = coinTxPayload.getPublicKeyUser();
    // senderPublicKeyField.copyBuffer(this.publicKey.toArrayBuffer());

    // Save my target public key
    // const targetUserAccountBuffer = targetUserAccount.buffer;
    //
    // if (!targetUserAccountBuffer) {
    //   throw new TargetUserAccountBufferIsNullError();
    // }
    //
    // coinTxPayload.initAcctNumReceive(targetUserAccountBuffer.byteLength);
    // const receiverAccountField = coinTxPayload.getAcctNumReceive();
    // receiverAccountField.copyBuffer(targetUserAccountBuffer);

    uvtData.initCoinTxPayload();
    uvtData.setCoinTxPayload(coinTxPayload);

    return uvtData;
  }

  private static createUvtHlConsumeStandTxData(
    chainNumber: number,
    blockNumber: number,
    nodeNumber: number,
    // targetUserAccount: PublicAccount,
    preImage: string,
  ): UvtData {
    const innerMessage = new MessageBase();
    const uvtData = innerMessage.initRoot(UvtData);

    const hlTxPayloadMessage = new MessageBase();
    const hlTxPayload = hlTxPayloadMessage.initRoot(HlTxPayload);

    // Initialize header
    uvtData.setBlockNumber(blockNumber);
    uvtData.setChainNumber(chainNumber);
    uvtData
      .setNodeNumber(nodeNumber);
    uvtData.setTxType(TxType.HL_CONSUME_STAND_TX);

    // Payload Data

    // Save destination account
    const hlConsumeStandTx = hlTxPayload.getHlConsumeStandTx();
    // const targetUserAccountBuffer = targetUserAccount.buffer;
    //
    // if (!targetUserAccountBuffer) {
    //   throw new TargetUserAccountBufferIsNullError();
    // }

    // hlConsumeStandTx.initAcctNumReceive(targetUserAccountBuffer.byteLength);
    // hlConsumeStandTx.getAcctNumReceive().copyBuffer(targetUserAccountBuffer);
    //
    // const preImageBuffer = serializer.decode64(preImage);
    // hlConsumeStandTx.initHlPreImage(preImageBuffer.byteLength);
    // hlConsumeStandTx.getHlPreImage().copyBuffer(preImageBuffer);
    //
    uvtData.initHlTxPayload();
    uvtData.setHlTxPayload(hlTxPayload);

    return uvtData;
  }
}
