<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper max-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <div class="modal-main-title">
              Welcome to Go Micro!
            </div>
            <div style="font-size: 22px; max-width: 400px;">
              Discover how Geeq is revolutionizing how you pay and get paid.
            </div>
            <div class="orange-txt">it’s pretty cool</div>
            <div
              class="modal-text modal-main-text"
              style="margin-bottom: 25px;"
            >
              You will need to install
              <a href="https://simulator.geeq.io/" class="modal-ln"
                >Go Micro v0.1</a
              >
              from GeeqOfficial in the Chrome extension store.
            </div>
            <br />
            <a
              href="https://chrome.google.com/webstore/detail/go-micro/olhdhgpdljlgklccnbpinmninjakknpe"
              target="_blank"
              class="modal-button modal-blue-button"
            >
              VISIT EXTENSION STORE
            </a>
            <a
              href="https://geeq.io/v021-faq1"
              class="modal-button modal-l-blue-button"
            >
              CLICK HERE FOR INSTRUCTIONS
            </a>
            <div class="modal-text modal-main-text">
              Only works in Desktop using Microsoft or MacOS
            </div>
            <div class="modal-radio">
              <label>
                <input @change="doNotShow = true" type="radio" name="radio" />
                do not show this message again
              </label>
            </div>
            <button class="modal-blue-button px-5 uppercase" @click="closeModal()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'NewVisitorModal',
  props: {
    //
  },
  data() {
    return {
      doNotShow: false,
    };
  },
  methods: {
    setupCookie() {
      if (!this.doNotShow) return;
      const expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      const expires = `expires=${expiryDate.toUTCString()}`;
      document.cookie = `doNotShow=1;${expires};path=/`;
    },
    closeModal() {
      this.setupCookie();
      this.$emit('closeVisitorModal');
    },
  },
};
</script>

<style scoped>
.modal-mask {
  background: rgba(255, 255, 255, 0.5);
}
.modal-container {
  width: auto;
  margin: 0px auto;
  padding: 0 0 0 0 !important;
  transition: all 0.3s ease;
  text-align: left;
  min-width: initial;
  max-width: 800px;
  font-family: 'Jost', sans-serif;
  border: 15px solid #2c4164;
  position: relative;
}
.modal-body {
  margin: 0;
  overflow-y: hidden;
  font-family: 'Jost', sans-serif;
  width: auto;
  text-align: center;
  padding: 40px 20px;
  border: 5px solid #449a98;
  display: block;
}
.modal-main-title {
  color: #449a98;
  font-size: 3em;
}
.modal-text {
  /* font-size: 16px; */
  text-align: center;
  max-width: 340px;
}
/* .modal-main-text {
  margin-top: 40px;
  margin-bottom: 10px;
} */
.modal-sec-text {
  margin-top: 20px;
  margin-bottom: 20px;
}
.max-wrapper .modal-button {
  border: none;
  color: #fff;
  padding: 2px 30px;
  min-width: 90px;
  cursor: pointer;
  margin: 0 5px 5px 5px;
}
.max-wrapper .modal-blue-button {
  background-color: #449a98;
}
.max-wrapper .modal-l-blue-button {
  background-color: #5fbdd1;
}
.max-wrapper .modal-ln {
  color: #479997;
  font-weight: 600;
}
.modal-radio input {
  min-width: 20px;
}
.modal-radio label {
  font-weight: 400;
}
.orange-txt {
  color: #c4744f;
  font-size: 2.3em;
}
.modal-blue-button {
  color: #fff;
  border: none;
}
</style>
