/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import BaseCard from './components/BaseCard.vue';
import '@/assets/css/index.css';

const app = createApp(App);
app.use(store).use(router);
app.component('base-card', BaseCard);
app.mount('#app');
