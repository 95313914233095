// #region imports
// Libraries
import { Message } from 'capnp-ts';
// Capnp messages
import {
  HlCreatePayload,
  HlConsumeSecTx,
  HlTxPayload,
  HlCreateSecTx,
  HlUnlockSecTx,
} from '@geeqdev/geeq_capnp_ts/generated/uvt.capnp';
import { TxType } from '@geeqdev/geeq_capnp_ts/generated/messageTxTypes.capnp';

// Actor related
import { GeeqUser } from 'geeq_node_core/src/actors/geeq-user';

// Utilities
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import { HashDigest } from 'geeq_node_core/src/libraries/buffers/hash-digest';
// Peer modules
import { Preimage } from 'geeq_node_core/src/libraries/preimage';

import { Subtle } from '@/models/subtle';
import { Serializer } from '@/models/serializer';

import { HlTester, PreImageType } from './HlTester';
// #endregion

export default class HlSecTester extends HlTester {
  // Test related instance variables
  testPreImageSecP1: ArrayBuffer

  testPreImageSecP2: ArrayBuffer

  constructor() {
    super();
    this.testPreImageSecP1 = this.getPreImage(PreImageType.P3a);
    this.testPreImageSecP2 = this.getPreImage(PreImageType.P3b);
  }

  async testHLCreateSec(
    sendingUser: GeeqUser,
    blockNumRfd: number,
    blockNumber: number,
    chainNumber: number,
  ): Promise<void> {
    // DEBUG: console.log('testHLCreateSec:', targetUser.userName)

    const callbackFn = async (hlCreateTxPayload: HlCreatePayload) => {
      // Set sec specific fields
      const hlCreaterSecTxMessage = new Message();
      const hlCreateSecTx = hlCreaterSecTxMessage.initRoot(HlCreateSecTx);

      // Lock with P2 in hlDigest2
      const hashDigest = new HashDigest(new Preimage(new Subtle()), new Serializer(), null);
      const hlDigest2 = await hashDigest.fromPreImage(this.testPreImageSecP2);
      if (hlDigest2.buffer == null) {
        throw new Error('hlDigest2 buffer is null in testHLCreateCert');
      }
      hlCreateSecTx.initHlDigest2(hlDigest2.buffer.byteLength);
      hlCreateSecTx.getHlDigest2().copyBuffer(hlDigest2.buffer);

      hlCreateTxPayload.setHlCreateSecTx(hlCreateSecTx);

      // DEBUG: console.log(hlCreateTxPayload.isHlCreateSecTx())
    };

    await this.sendUvtHlCreateTxPayloadMessage(
      TxType.HL_CREATE_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      1, // nonce
      GeeqAmount.fromGeeqs(4), // amtTx
      blockNumRfd,
      this.testPreImageSecP1, // Lock with P1 in hlDigest1
      callbackFn,
    );
  }

  async testHLUnlockConsumeSec(
    sendingUser: GeeqUser,
    targetUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // DEBUG: console.log('testHLUnlockSec')

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlUnlockSecTxMessage = new Message();
      const hlUnlockSecTx = hlUnlockSecTxMessage.initRoot(HlUnlockSecTx);

      // Set hlDigest1 = Hash (P2 | acctNumReceive)
      const acctNumReceive = await targetUser.getPublicAccountKey().getAccount();
      if (acctNumReceive.buffer == null) {
        throw new Error('acctNumReceive is null in testHLUnlockSec');
      }

      const hashDigest = new HashDigest(new Preimage(new Subtle()), new Serializer(), null);
      const hlDigest1 = await hashDigest.fromSecUvtFields(
        this.testPreImageSecP2, // P2
        acctNumReceive.buffer, // AcctNumReceive
      );

      if (hlDigest1.buffer === null) {
        throw new Error('hlDigest1 buffer is null in testHLCreateCert');
      }

      hlUnlockSecTx.initHlDigest1(hlDigest1.buffer.byteLength);
      hlUnlockSecTx.getHlDigest1().copyBuffer(hlDigest1.buffer);

      // Set preimage to unlock HL Account Record
      hlUnlockSecTx.initHlPreImage(this.testPreImageSecP1.byteLength);
      hlUnlockSecTx.getHlPreImage().copyBuffer(this.testPreImageSecP1);

      hlTxPayload.setHlUnlockSecTx(hlUnlockSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_UNLOCK_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }

  async testHLConsumeSec(
    sendingUser: GeeqUser,
    blockNumber: number,
    chainNumber: number,
  ) {
    // DEBUG: console.log('testHLConsumeSec')

    const callbackFn = async (hlTxPayload: HlTxPayload) => {
      // Set sec specific fields
      const hlCreateSecTxMessage = new Message();
      const hlCreateSecTx = hlCreateSecTxMessage.initRoot(HlConsumeSecTx);

      // Pass in acctNumReceive
      const acctNumReceive = await sendingUser.getPublicAccountKey().getAccount();

      if (acctNumReceive == null) {
        throw new Error('acctNumReceive is null in testHLConsumeSec');
      }

      const acctNumReceiveBuffer = acctNumReceive.buffer;

      if (acctNumReceiveBuffer == null) {
        throw new Error('acctNumReceiveBuffer is null in testHLConsumeSec');
      }

      hlCreateSecTx.initAcctNumReceive(acctNumReceiveBuffer.byteLength);
      hlCreateSecTx.getAcctNumReceive().copyBuffer(acctNumReceiveBuffer);

      // Pass in preImage, which unlocks the HL
      const preImage = this.testPreImageSecP2;
      hlCreateSecTx.initHlPreImage(preImage.byteLength);
      hlCreateSecTx.getHlPreImage().copyBuffer(preImage);

      hlTxPayload.setHlConsumeSecTx(hlCreateSecTx);
    };

    await this.sendUvtHlTxPayloadMessage(
      TxType.HL_CONSUME_SEC_TX,
      sendingUser,
      blockNumber,
      chainNumber,
      callbackFn,
    );
  }
}
