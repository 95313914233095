import {
  Logger as LoggerInterface,
  LoggerRender,
} from 'geeq_node_core/src/logger/interfaces/logger';
import { Log } from 'geeq_node_core/src/logger/interfaces/log';

export class Logger implements LoggerInterface {
  private readonly render: LoggerRender;

  constructor(render: LoggerRender) {
    this.render = render;
  }

  public send(log: Log): void {
    this.render(log);
  }
}
