/* eslint-disable class-methods-use-this */
import {
  TransportEvent,
  TransportHandler,
  Params,
} from 'geeq_node_core/src/transport/interfaces';
import {
  Transport,
} from 'geeq_node_core/src/transport';

export class Network extends Transport {
  private static register: Record<string, Network> = {};

  private readonly handlers: Partial<Record<TransportEvent, TransportHandler>> = {};

  constructor(ip: string) {
    super();
    Network.register[ip] = this;
  }

  public async send(
    ip: string,
    type: TransportEvent,
    params: Params,
  ): Promise<boolean> {
    const network = Network.register[ip];

    if (!network) {
      return false;
    }

    const handler = network.handlers[type];

    if (!handler) {
      return false;
    }

    await handler(params);

    return true;
  }

  public subscribe(type: TransportEvent, callback: TransportHandler): void {
    this.handlers[type] = callback;
  }

  public static clearRegister(): void {
    Network.register = {};
  }
}
