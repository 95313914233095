/* eslint-disable class-methods-use-this,no-await-in-loop */
import { Crypto } from 'geeq_node_core/src/libraries/crypto';
import { Preimage } from 'geeq_node_core/src/libraries/preimage';
import { GeeqAmount } from 'geeq_node_core/src/libraries/geeq-amount';
import { LedgerFactory } from 'geeq_node_core/src/node/ledgers/factory';
import { Node } from 'geeq_node_core/src/actors/interfaces';
import { BaseActor } from 'geeq_node_core/src/actors/base';
import { ActiveActor } from 'geeq_node_core/src/actors/active';
import { ListenerActor } from 'geeq_node_core/src/actors/listener';
import { RelayActor } from 'geeq_node_core/src/actors/relay';
import { RelayManager } from 'geeq_node_core/src/node/relay/manager';

import { LedgerManager } from 'geeq_node_core/src/node/ledgers/manager';

import { BlockChain } from 'geeq_node_core/src/node/block-chain';

import { LoggerRender } from 'geeq_node_core/src/logger/interfaces/logger';
import { NodeManager } from 'geeq_node_core/src/node/manager';
import { HubManager } from 'geeq_node_core/src/node/hub/manager';
import { PublicKeyBuffer } from 'geeq_node_core/src/capnp/buffers/public-key';
import { BlockFactory } from 'geeq_node_core/src/node/blocks/factory';

import { Subtle } from '@/models/subtle';
import { Network } from '@/models/network';
import { ContentCreators } from '@/StaticProductsLists';
import { VllStorage } from '@/models/vll-storage';
import { AlbStorage } from '@/models/alb-storage';
import { VlbStorage } from '@/models/vlb-storage';
import { Logger } from '@/models/logger';
import TFBrowserTester from '@/tests/TFBrowserTester';
import { Serializer } from '@/models/serializer';
import { Dispatcher } from '@/models/worker/dispatcher';
import { GeeqUserSim } from '@/components/actors/GeeqUserSim';
import { UserType } from '@/components/actors/user-type';
import { Transaction } from '@/models/transaction';

const InitialActiveNodes = 3;
const InitialListenerNodes = 1;

export class Simulator {
  private readonly subtle: Subtle;

  private readonly preimage: Preimage;

  private readonly serializer: Serializer

  private readonly contentGeeqUsers = new Array<GeeqUserSim>();

  private readonly productToGeeqUser: Record<string, GeeqUserSim> = {};

  private readonly altGeeqUsers = Array<GeeqUserSim>();

  private geeqNodes = new Array<Node>();

  private readonly theTFBrowserTests: TFBrowserTester;

  private chainName: string;

  private activeNodes: number;

  private listenerNodes: number;

  private primaryGeeqUser?: GeeqUserSim;

  // private geeqUserSimulator?: GeeqUserSim;

  private transaction?: Transaction;

  private geeqCorp?: BaseActor; // Geeq Corp Singleton

  constructor() {
    this.subtle = new Subtle();
    this.preimage = new Preimage(this.subtle);
    this.serializer = new Serializer();
    this.chainName = `GeeqPay-${Math.floor(Math.random() * 100) + 1}`;
    this.activeNodes = InitialActiveNodes;
    this.listenerNodes = InitialListenerNodes;

    // Instantiate the singleton that encapsulates the tests
    this.theTFBrowserTests = new TFBrowserTester();
  }

  // #region getters and constructor
  public getChainName(): string {
    return this.chainName;
  }

  public setChainName(value: string): void {
    this.chainName = value;
  }

  public getActiveNodes(): number {
    return this.activeNodes;
  }

  public setActiveNodes(value: number): void {
    this.activeNodes = value;
  }

  public getListenerNodes(): number {
    return this.listenerNodes;
  }

  public setListerNodes(value: number): void {
    this.listenerNodes = value;
  }

  public getPrimaryGeeqUser(): GeeqUserSim | undefined {
    return this.primaryGeeqUser;
  }

  // public getGeeqUserSimulator(): GeeqUserSim | undefined {
  //   return this.geeqUserSimulator;
  // }

  public getTransaction(): Transaction | undefined {
    return this.transaction;
  }

  public getContentGeeqUsers(): Array<GeeqUserSim> {
    return this.contentGeeqUsers;
  }

  public getProductToGeeqUser(): Record<string, GeeqUserSim> {
    return this.productToGeeqUser;
  }

  public getAltGeeqUsers(): Array<GeeqUserSim> {
    return this.altGeeqUsers;
  }

  public getGeeqCorp(): BaseActor | undefined {
    return this.geeqCorp;
  }

  public getGeeqNodes(): Array<Node> {
    return this.geeqNodes;
  }
  // #endregion

  // #region test HL_CREATE_CERT_TX, and HL_CONSUME_CERT_TX code
  public async testHLCreateCert(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLCreateCert');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();

    await this.theTFBrowserTests
      .getHlCertTester()
      .testHLCreateCert(
        this.primaryGeeqUser,
        this.contentGeeqUsers[0],
        vllJson.blockNumber,
        42,
      );
  }

  public async testHLConsumeCert(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLCreateCert');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();

    await this.theTFBrowserTests
      .getHlCertTester()
      .testHLConsumeCert(
        this.primaryGeeqUser,
        vllJson.blockNumber,
        42,
      );
  }
  // #endregion

  // #region test HL_CREATE_SEC_TX, HL_UNLOCK_SEC_TX, HL_TRANSFER_SEC_TX and HL_CONSUME_SEC_TX code

  /* Alice creates an HL account record with hash locks only known to her.  Also auto-refund goes to
   * Alice desi
   * is set to Alice's account.
   */
  public async testHLCreateSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLCreateSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();

    const currBlockNum = vllJson.blockNumber;
    await this.theTFBrowserTests
      .getHlSecTester()
      .testHLCreateSec(
        this.primaryGeeqUser,
        currBlockNum + 1000, // blockNumRfd
        currBlockNum,
        42,
      );
  }

  // Bob unlocks the HL account record
  public async testHLUnlockConsumeSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLUnlockSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();

    const Bob = this.contentGeeqUsers[0];
    const currBlockNum = vllJson.blockNumber;

    await this.theTFBrowserTests
      .getHlSecTester()
      .testHLUnlockConsumeSec(
        Bob, // Sender of unlock TX
        Bob, // Recipient of consume TX
        currBlockNum,
        42,
      );
  }

  // Bob consumes the HL account record
  public async testHLConsumeSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLConsumeSecCommon');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();

    const Bob = this.contentGeeqUsers[0];
    const currBlockNum = vllJson.blockNumber;

    await this.theTFBrowserTests
      .getHlSecTester()
      .testHLConsumeSec(
        Bob,
        currBlockNum,
        42,
      );
  }

  /**
   * HL_TRANSFER_SEC_TX related tests
   */
  public async testHLCreateTransferSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLCreateSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();
    const currBlockNum = vllJson.blockNumber;
    await this.theTFBrowserTests
      .getHlSecTransferTester()
      .testHLCreateSec(
        this.primaryGeeqUser,
        currBlockNum + 1000, // blockNumRfd
        currBlockNum,
        42,
      );
  }

  // Test HL_UNLOCK_SEC (transfer)
  public async testHLUnlockTransferSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLUnlockSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();
    const currBlockNum = vllJson.blockNumber;

    const Bob = this.contentGeeqUsers[0];
    await this.theTFBrowserTests
      .getHlSecTransferTester()
      .testHLUnlockTransferSec(
        Bob,
        Bob,
        currBlockNum,
        42,
      );
  }

  /**
   * Bob transfers ownership of the HL account record to himself.
   * The HL account record contains new hash locks that only Bob knows,
   * and also the refund information is set to Bob's account.
   */
  public async testHLTransferSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLTransferSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();
    const Bob = this.contentGeeqUsers[0];
    const currBlockNum = vllJson.blockNumber;

    await this.theTFBrowserTests
      .getHlSecTransferTester()
      .testHLTransferSec(
        Bob,
        currBlockNum,
        42,
      );
  }

  // Test HL_UNLOCK_SEC (consume transfer)
  public async testHLUnlockTransferConsumeSec(): Promise<void> {
    if (!this.primaryGeeqUser) {
      throw new Error('Primary Geeq User not initialized in testHLUnlockSec');
    }

    const vllJson = await this.geeqNodes[0].getVllJson();
    const currBlockNum = vllJson.blockNumber;
    const Charlie = this.contentGeeqUsers[1];

    await this.theTFBrowserTests
      .getHlSecTransferTester()
      .testHLUnlockTransferConsumeSec(
        Charlie,
        Charlie,
        currBlockNum,
        42,
      );
  }

  public async testHLConsumeTransferSec(): Promise<void> {
    const vllJson = await this.geeqNodes[0].getVllJson();
    const currBlockNum = vllJson.blockNumber;
    const Charlie = this.contentGeeqUsers[1];

    await this.theTFBrowserTests.getHlSecTransferTester().testHLConsumeSec(
      Charlie,
      currBlockNum,
      42,
    );
  }
  // #endregion

  // #region test Proxy accounts
  public async testProxyTx(): Promise<void> {
    // const vllJson = await this.geeqNodes[0].getVllJSON();
    // this.primaryGeeqUser?.testProxyCreateRecord(
    //   vllJson.blockNumber,
    //   42,
    // );
  }

  public async testProxyTx2(): Promise<void> {
    // const vllJson = await this.geeqNodes[0].getVllJSON();
    // this.primaryGeeqUser?.testProxyCreateMultiUser(
    //   vllJson.blockNumber,
    //   42,
    // );
  }

  public async testProxyTx3(): Promise<void> {
    // const vllJson = await this.geeqNodes[0].getVllJSON();
    // this.primaryGeeqUser?.testProxyRemoveMultiUser(
    //   vllJson.blockNumber,
    //   42,
    // );
  }

  public async testProxyTx4(): Promise<void> {
    // const vllJson = await this.geeqNodes[0].getVllJSON();
    // this.primaryGeeqUser?.testProxyRemoveRecord(
    //   vllJson.blockNumber,
    //   42,
    // );
  }

  /**
   * Send COIN_PART_TX
   */
  public async sendCoinPartTransaction(
    nodeIp: string,
    // targetUserAccount: PublicAccount,
    amount: GeeqAmount,
    ecKeys: Crypto,
    preimage: Preimage,
    serializer: Serializer,
  ) {
    // Create new block data
    // const message = new MessageBase();
    // const uvt = message.initRoot(UVT);
    // const { primaryGeeqUser } = this;
    // const userSim = this.geeqUserSimulator;
    // if (!primaryGeeqUser || !userSim) return false;
    //
    // const nodeIndex = userSim.getNodeIndexByIP(nodeIp);
    // const nodeNumber = userSim.getNodeNumberByIp(nodeIp);
    // if (!nodeNumber) {
    //   throw new MissingNodeNumError();
    // }
    //
    // // TODO: Need to get
    // const targetBlockNumber = 0;
    // const userTxNumber = userSim.nextUserTxNumber(targetBlockNumber);
    // const uvtData = userSim.createUvtCoinTxPartData(
    //   primaryGeeqUser.targetChainNumber,
    //   targetBlockNumber,
    //   userTxNumber,
    //   nodeNumber,
    //   targetUserAccount,
    //   amount,
    //   primaryGeeqUser.getPublicKey(),
    // );
    // // Initialize block with data
    // uvt.setUvtData(uvtData);
    //
    // // Allocate space for signature
    // uvt.initSenderSignature(64);
    //
    // const dispatcher = new Dispatcher();
    // const flatBuffer = await getUvtFlatten(
    //   dispatcher,
    //   serializer,
    //   message.toPackedArrayBuffer(),
    // );
    //
    // // Add signature
    // const uvtWrapper = new UvtWrapper(
    //   ecKeys,
    //   preimage,
    //   serializer,
    //   dispatcher,
    //   message.toPackedArrayBuffer(),
    //   flatBuffer,
    // );
    // await uvtWrapper.signUVT();
    //
    // // Log that we created the UVT
    // await primaryGeeqUser.addToLog(
    //   `UVT (COIN_PART_TX) generated (destination nodeIndex=${nodeIndex}, recipient account = ${
    //     targetUserAccount.shortFormat
    //   }, amt=${amount.format()})`,
    //   uvtWrapper,
    // );
    //
    // // Send UVT the node
    // return primaryGeeqUser.sendUVT(nodeIndex, uvtWrapper.sourceBuffer);
  }
  // #endregion

  // #region create actors (GeeqCorp, GeeqUsers, and GeeqNodes)

  public async createGeeqCorp(eventLogger: LoggerRender) {
    const ip = this.getRandomIPAddress();
    const ecKeys = new Crypto(this.subtle);

    this.geeqCorp = new BaseActor(
      ip,
    );
    this.geeqCorp.setContext({
      crypto: ecKeys,
      transport: new Network(ip),
      dispatcher: new Dispatcher(),
      logger: new Logger(eventLogger),
    });
    console.log('corp private key');
    await this.geeqCorp.initKeys();
  }

  public async createGeeqUsers(
    eventLogger: { (event: unknown): void },
    userName: string,
    userType: UserType,
    primaryUserInitialBalance: GeeqAmount,
    storeInitialBalance: GeeqAmount,
    altUserInitialBalance: GeeqAmount,
    numAltUsers: number,
    numVisibleAltUsers: number,
    defaultAltUserName: string,
  ): Promise<void> {
    if (!this.geeqCorp) {
      throw new Error('The Geeq Corp is not initialized');
    }

    const publicKey = this.geeqCorp.getPublicKey();

    if (!publicKey) {
      throw new Error('The Geeq Corp is not initialized');
    }

    await this.createPrimaryGeeqUser(
      publicKey,
      userName,
      userType,
      primaryUserInitialBalance,
      eventLogger,
    );

    // await this.createGeeqUserSim(
    //   publicKey,
    //   userName,
    //   userType,
    //   primaryUserInitialBalance,
    //   eventLogger,
    // );

    await this.createStoreGeeqUsers(
      eventLogger,
      publicKey,
      storeInitialBalance,
    );

    await this.createAltUsers(
      eventLogger,
      publicKey,
      altUserInitialBalance,
      numAltUsers,
      numVisibleAltUsers,
      defaultAltUserName,
    );
  }

  public async clearGeeqNodes(): Promise<void> {
    this.geeqNodes = [];
  }

  public async createGeeqNodes(
    waitForTxInterval: number,
    waitForNvuInterval: number,
    eventLogger: LoggerRender,
  ): Promise<void> {
    if (this.geeqCorp == null) {
      throw new Error('Geeq Corp is null in createGeeqNodes');
    }

    if (this.geeqCorp.getPublicKey() == null) {
      throw new Error('Geeq Corp public key is null in createGeeqNodes');
    }

    if (!this.primaryGeeqUser) {
      throw new Error('Primary geeq user is null in createGeeqNodes');
    }

    let nodeNumber = 2;
    await Promise.all(
      new Array(this.activeNodes)
        .fill(1)
        .map(async (value, index) => {
          if (!this.geeqCorp) {
            return undefined;
          }

          const ip = this.getRandomIPAddress();
          const userECKeys = new Crypto(this.subtle);
          const crypto = new Crypto(this.subtle);
          const dispatcher = new Dispatcher();

          await userECKeys.generatePair();

          const node = new ActiveActor(
            ip,
            this.geeqCorp.getPublicKey(),
            nodeNumber + index,
            3000,
            userECKeys.getPublicKey(),
          );

          node.setContext({
            transport: new Network(ip),
            logger: new Logger(eventLogger),
            crypto,
            dispatcher,
          });

          const blockFactory = new BlockFactory(
            crypto,
            dispatcher,
            node,
          );
          const blockChain = new BlockChain(
            crypto,
            blockFactory,
            new VlbStorage(node),
            new AlbStorage(node),
            node,
          );

          const ledgerFactory = new LedgerFactory(
            crypto,
            dispatcher,
          );
          const ledgerManager = new LedgerManager(
            crypto,
            dispatcher,
            new VllStorage(),
            blockChain,
            ledgerFactory,
          );

          const nodeManager = new NodeManager(
            crypto,
            dispatcher,
            node,
          );
          const hubManager = new HubManager(
            crypto,
            dispatcher,
            node,
          );

          blockChain.setLedgerManager(ledgerManager);
          node.setBlockChain(blockChain);
          node.setNodeManager(nodeManager);
          node.setHubManager(hubManager);

          await node.initKeys();

          this.geeqNodes.push(node);

          return undefined;
        }),
    );

    nodeNumber += this.activeNodes;
    await Promise.all(
      new Array(this.listenerNodes)
        .fill(1)
        .map(async (value, index) => {
          if (!this.geeqCorp) {
            return undefined;
          }

          const ip = this.getRandomIPAddress();
          const crypto = new Crypto(this.subtle);
          const dispatcher = new Dispatcher();

          const node = new ListenerActor(
            ip,
            this.geeqCorp.getPublicKey(),
            nodeNumber + index,
          );

          node.setContext({
            transport: new Network(ip),
            logger: new Logger(eventLogger),
            dispatcher,
            crypto,
          });

          const blockFactory = new BlockFactory(
            crypto,
            dispatcher,
            node,
          );
          const blockChain = new BlockChain(
            crypto,
            blockFactory,
            new VlbStorage(node),
            new AlbStorage(node),
            node,
          );

          const ledgerFactory = new LedgerFactory(
            crypto,
            dispatcher,
          );
          const ledgerManager = new LedgerManager(
            crypto,
            dispatcher,
            new VllStorage(),
            blockChain,
            ledgerFactory,
          );

          const nodeManager = new NodeManager(
            crypto,
            dispatcher,
            node,
          );

          blockChain.setLedgerManager(ledgerManager);
          node.setBlockChain(blockChain);
          node.setNodeManager(nodeManager);

          await node.initKeys();

          this.geeqNodes.push(node);

          return undefined;
        }),
    );
    nodeNumber += this.listenerNodes;
    await Promise.all(
      new Array(1)
        .fill(1)
        .map(async () => {
          if (!this.geeqCorp) {
            return undefined;
          }

          const ip = this.getRandomIPAddress();
          const crypto = new Crypto(this.subtle);
          const dispatcher = new Dispatcher();

          const node = new RelayActor(
            ip,
            this.geeqCorp.getPublicKey(),
            nodeNumber,
          );

          node.setContext({
            transport: new Network(ip),
            logger: new Logger(eventLogger),
            dispatcher,
            crypto,
          });

          const blockFactory = new BlockFactory(
            crypto,
            dispatcher,
            node,
          );
          const blockChain = new BlockChain(
            crypto,
            blockFactory,
            new VlbStorage(node),
            new AlbStorage(node),
            node,
          );

          const ledgerFactory = new LedgerFactory(
            crypto,
            dispatcher,
          );
          const ledgerManager = new LedgerManager(
            crypto,
            dispatcher,
            new VllStorage(),
            blockChain,
            ledgerFactory,
          );

          const nodeManager = new NodeManager(
            crypto,
            dispatcher,
            node,
          );

          const relayManager = new RelayManager(
            crypto,
            dispatcher,
            node,
          );

          blockChain.setLedgerManager(ledgerManager);
          node.setBlockChain(blockChain);
          node.setNodeManager(nodeManager);
          node.setRelayManager(relayManager);

          await node.initKeys();

          this.geeqNodes.push(node);

          return undefined;
        }),
    );
  }

  public async createTransaction(eventLogger: LoggerRender): Promise<void> {
    const ip = this.getRandomIPAddress();
    const crypto = new Crypto(this.subtle);

    this.transaction = new Transaction(
      crypto,
      new Network(ip),
      new Dispatcher(),
      this.geeqNodes[0],
      new Logger(eventLogger),
    );

    await this.transaction.initKeys();
  }

  private getRandomIPAddress(): string {
    return new Array(4)
      .fill(1)
      .map(() => Math.floor(Math.random() * 255) + 1)
      .join('.');
  }

  // private async createGeeqUserSim(
  //   geeqCorpPublicKey: PublicKeyBuffer,
  //   userName: string,
  //   userType: UserType,
  //   initialBalance: GeeqAmount,
  //   eventLogger: LoggerRender,
  // ): Promise<void> {
  //   const ip = this.getRandomIPAddress();
  //   const ecKeys = new ECKeys(this.subtle);
  //
  //   this.geeqUserSimulator = new GeeqUserSim(
  //     ip,
  //     ecKeys,
  //     this.preimage,
  //     this.serializer,
  //     new Network(ip),
  //     new Dispatcher(),
  //     geeqCorpPublicKey,
  //     initialBalance,
  //     true, // isVisible
  //     userName,
  //     true, // isPrimary
  //     false, // isContentCreator
  //     false, // isNodeUser
  //     userType,
  //     new Logger(eventLogger),
  //   );
  //
  //   await this.geeqUserSimulator.initKeys();
  // }

  private async createPrimaryGeeqUser(
    geeqCorpPublicKey: PublicKeyBuffer,
    userName: string,
    userType: UserType,
    initialBalance: GeeqAmount,
    eventLogger: LoggerRender,
  ): Promise<void> {
    const ip = this.getRandomIPAddress();
    const crypto = new Crypto(this.subtle);

    this.primaryGeeqUser = new GeeqUserSim(
      ip,
      crypto,
      this.preimage,
      new Network(ip),
      new Dispatcher(),
      geeqCorpPublicKey,
      initialBalance,
      true, // isVisible
      userName,
      true, // isPrimary
      false, // isContentCreator
      false, // isNodeUser
      userType,
      new Logger(eventLogger),
    );

    await this.primaryGeeqUser.initKeys();
  }

  private async createStoreGeeqUsers(
    eventLogger: LoggerRender,
    geeqCorpPublicKey: PublicKeyBuffer,
    initialBalance: GeeqAmount,
  ) {
    // Create Content Creator users
    this.contentGeeqUsers.splice(0);
    const count = this.activeNodes + this.listenerNodes + 1;
    for (let i = 0; i < count; i++) {
      const contentCreator = ContentCreators[i % 4];
      const ip = this.getRandomIPAddress();
      const crypto = new Crypto(this.subtle);

      const geeqUser = new GeeqUserSim(
        ip,
        crypto,
        this.preimage,
        new Network(ip),
        new Dispatcher(),
        geeqCorpPublicKey,
        initialBalance,
        true, // visible
        contentCreator.userName,
        false, // isPrimary
        true, // isContentCreator
        false, // isNodeUser
        contentCreator.userType,
        new Logger(eventLogger),
      );

      await geeqUser.initKeys();
      this.contentGeeqUsers.push(geeqUser);

      for (let j = 0; j < contentCreator.products.length; j++) {
        this.productToGeeqUser[contentCreator.products[j].id] = geeqUser;
      }
    }
  }

  private async createAltUsers(
    eventLogger: LoggerRender,
    geeqCorpPublicKey: PublicKeyBuffer,
    altUserInitialBalance: GeeqAmount,
    numAltUsers: number,
    numVisibleAltUsers: number,
    defaultAltUserName: string,
  ) {
    this.altGeeqUsers.splice(0);
    let visibleAltUserCount = 0;
    for (let i = 0; i < numAltUsers; i++) {
      const isVisible = visibleAltUserCount < numVisibleAltUsers; // Mark subset of users as visible
      visibleAltUserCount += 1;
      const ip = this.getRandomIPAddress();
      const crypto = new Crypto(this.subtle);

      const geeqUser = new GeeqUserSim(
        ip,
        crypto,
        this.preimage,
        new Network(ip),
        new Dispatcher(),
        geeqCorpPublicKey,
        altUserInitialBalance,
        isVisible,
        defaultAltUserName,
        false, // isPrimary
        false, // isContentCreator
        false, // isNodeUser
        UserType.person,
        new Logger(eventLogger),
      );

      await geeqUser.initKeys();
      this.altGeeqUsers.push(geeqUser);
    }
  }

  // #endregion
}
