/* eslint-disable no-shadow */
const SimStateUninitialized = 0;
const SimStateInitial = 1;
const SimStateRunning = 2;
const SimStatePaused = 3;
const SimStateCompleted = 4;

enum SimulationStates {
  SIM_STATE_UNINITIALIZED = 0,
  SIM_STATE_INITIAL = 1,
  SIM_STATE_RUNNING = 2,
  SIM_STATE_PAUSED = 3,
  SIM_STATE_COMPLETED = 4,
}

export {
  SimStateUninitialized,
  SimStateInitial,
  SimStateRunning,
  SimStatePaused,
  SimStateCompleted,
  SimulationStates,
};
